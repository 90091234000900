import React, { Component } from 'react';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
const styles = theme => ({
  button: {
    display: "inline-block",
    fontWeight: 400,
    textAlign: "center",
    userSelect: "none",
    backgroundColor: "transparent",
    border: "1px solid transparent",
    transition: "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
    padding: ".5rem 3.75rem",
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
    borderRadius: "50rem",
    fontSize: ".875rem",
    lineHeight: 1.5,
    cursor: 'pointer',
    "&:focus": {
      outline: 0
    }
  }
})

class LoginButton extends Component {
  render() {
    const {text, className, classes, ...rest} = this.props;
    return (
      <input className={[classes.button, className].join(' ')} type="submit" {...rest} value={text}/>
    );
  }
}

LoginButton.propTypes = {
  text: PropTypes.string.isRequired
}

export default withStyles(styles)(LoginButton);