import React, { Component } from 'react';
import {SupervisorAccount, Forum, Help, CloudDownload} from '@material-ui/icons';
import {withStyles} from '@material-ui/styles';
import {withRouter} from 'react-router-dom';
import {Alerts, LoadingSpinner} from '../../components';
import {Grid, Card} from '@material-ui/core';
import {Tile, RegisteredCustomers, RecentUsage} from './components';
import {getStatistics, getMongoCustomerData, getSurveysFromMongo} from '../../indexBackend';
import moment from 'moment';
import axios from 'axios';
const styles = theme => ({
  card: {
    padding: theme.spacing(2)
  }
})

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: null,
      customers: null,
      usage: null
    }
  }

  componentDidMount() {
    axios({
      url: '/api/checkLogin',
      method: 'POST',
      headers: { 'content-type': 'application/json', 'Authorization': 'Bearer '+ localStorage.getItem('token') },
    }).then((data) => {
      this.getStats();
      this.getCustomerData();
    })
    .catch(err => {
      this.props.history.push('/login');
    })
  }

  async getCustomerData() {
    let customerData = await getMongoCustomerData();
    let usage = await getSurveysFromMongo({ after: moment().subtract(3, 'months').format('x') })
    this.setState(state => ({
      ...state,
      customers: customerData.data.data,
      usage: usage.data.data
    }))
  }

  getStats = async () => {
    const stats = await getStatistics();
    let customers = await getMongoCustomerData();
      
    this.setState(state => ({
      ...state,
      stats: stats.data.data,
      customers: customers.data.data
    }), () => {
      this.getLicencedCustomers()
    })
  }

  getAverageDaily = () => {
    const stats = [...this.state.usage].sort((a,b) => {
      if(parseInt(a.timestamp) > parseInt(b.timestamp)) {
        return -1
      } else if(parseInt(a.timestamp) < parseInt(b.timestamp)) {
        return 1
      } else {
        return 0
      }
    })


    if(stats.length) {
      const min = [...stats].shift();
      const max = [...stats].pop();

      const diff = moment(min.timestamp).diff(moment(max.timestamp), 'days')
  
      if(diff < 1) {
        return stats.length;
      }

      return Math.floor(stats.length / diff)
    }

  }

  getLicencedCustomers = () => {
    const customers = [...this.state.customers];

    const filtered = customers.filter(x => {
      if(moment() < moment(x.expiry)) {
        return true
      }
    })

    return filtered.length
  }
  
  render() {
    const {classes} = this.props;

    if(!this.state.customers || !this.state.stats || !this.state.usage){
      return <LoadingSpinner/>
    }

    return (

      <Card elevation={0} className={classes.card}>
        <Grid 
        container
        spacing={2}
        >
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Tile
              icon={<SupervisorAccount/>}
              primaryText={this.getLicencedCustomers() || "0"}
              secondaryText="Licensed Customers"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Tile
              icon={<Forum/>}
              color="secondary"
              primaryText={this.getAverageDaily() || "0"}
              secondaryText="Average Daily Use"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Tile
              icon={<Help/>}
              color="warning"
              primaryText={this.state.stats?.Surveys || "0"}
              secondaryText="Total Surveys"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Tile
              icon={<CloudDownload/>}
              color="info"
              primaryText={this.state.stats?.Comments || "0"}
              secondaryText="Comments Made"
            />
          </Grid>
          <RegisteredCustomers customers={this.state.customers} customerStats={this.state.stats.customerTotal}/>
          <RecentUsage usage={this.state.usage}/>
        </Grid>
      </Card>
    );
  }
}

export default withRouter(withStyles(styles)(Dashboard));
