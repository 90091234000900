import React, { Component } from 'react';
import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {TextInput, Button} from '../../../../components';
class Dialog extends Component {
  render() {
    const {type, languagePack, open, dialogHandler, submit, fieldHandler, values, submitting} = this.props;
    
    if(!type || !languagePack) {
      return null;
    }
    
    const modalLanguagePack = languagePack[type]
    
    return (
      <MuiDialog open={open} onClose={dialogHandler}>
        <DialogTitle>
          {modalLanguagePack.heading}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput value={values.subject} label={modalLanguagePack.fields.subject.label} onChange={fieldHandler} name={`${type}-subject`} disabled={submitting}/>
            </Grid>
            <Grid item xs={12}>
              <TextInput multiline rows={4} value={values.description} label={modalLanguagePack.fields.description.label} onChange={fieldHandler} name={`${type}-description`} disabled={submitting}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={submit} onClick={dialogHandler}>
            {modalLanguagePack.buttons.cancel}
          </Button>
          <Button fullWidth disableElevation color='success' variant='contained' onClick={submit} disabled={!values.subject || !values.description || submitting}>
            {modalLanguagePack.buttons.submit}
          </Button>
        </DialogActions>
      </MuiDialog>
    );
  }
}

export default Dialog;