import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Divider, ListSubheader } from '@material-ui/core';
import { Button } from '../../../../components';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/styles';
import {Autocomplete} from '@material-ui/lab';


const styles = theme => ({
  list: {
    maxHeight: '300px', 
    backgroundColor: "#fff", 
    overflowY: 'scroll'
  }
})

class LanguageDialog extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getAvailableLanguages() 
  }

  getAvailableLanguages = () => {
    const available = Object.keys(this.props.allLanguages).map(x => {      
      if(!this.props.enabledLanguages?.find(k => k.language === x)){
        return {languageCode: x, language: this.props.allLanguages[x]}
      }
      return;
    }).filter(k => k !== undefined)

    return available;
  }

  render() {
    const {classes, step, open, close, exit, stepHandler, languagePack, enabledLanguages, allLanguages} = this.props;

    const steps = [
      {
        header: (
          languagePack.languages.heading
        ),
        content: (
          <List
            className={classes.list}
            subheader={(
              <React.Fragment>
                <ListSubheader component='div' style={{backgroundColor: '#fff'}}>
                  {languagePack.languages.subheading}
                </ListSubheader>
                <Divider/>
              </React.Fragment>
            )}
          >
            {
              enabledLanguages ? enabledLanguages.map(x => (
                <React.Fragment key={x.language}>
                  <ListItem>
                    <ListItemText primary={allLanguages[x.language]}/>
                  </ListItem>
                  <Divider/>
                </React.Fragment>

              )) : null
            }
          </List>
        ),
        footer: (
          <React.Fragment>
            <Button fullWidth onClick={close}>
              {languagePack.languages.buttons.close}
            </Button>
            <Button fullWidth color='success' variant='contained' onClick={() => stepHandler('next')}>
              {languagePack.languages.buttons.enable}
            </Button>
          </React.Fragment>
        )
      },
      {
        header: (
          languagePack.newLanguage.heading
        ),
        content: (
          null
        ),
        footer: (
          <React.Fragment>
            <Button fullWidth onClick={() => stepHandler('prev')}>
              {languagePack.newLanguage.buttons.cancel}
            </Button>
            <Button fullWidth color='success' variant='contained' onClick={() => stepHandler('next')}>
              {languagePack.newLanguage.buttons.next}
            </Button>
          </React.Fragment>
        )
      }
    ]

    return (
      <Dialog fullWidth maxWidth="sm" open={open} onClose={close} onExited={exit}>
        <DialogTitle>
          {steps[step].header}
        </DialogTitle>
        <DialogContent>
          {steps[step].content}
        </DialogContent>
        <DialogActions>
          {steps[step].footer}
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({enabled, languages}) => {
  const page = window.location.pathname.split('/').pop()
  return {
    enabledLanguages: enabled,
    allLanguages: languages
  }
}

export default connect(mapStateToProps)(withStyles(styles)(LanguageDialog));