import React from 'react';
import { TableCell } from '@material-ui/core';
import { Status } from '../../../';

const StatusTemplate = props => {
  const { text, color, colorVariant } = props;

  return (
    <TableCell>
      <Status text={text} color={color} colorVariant={colorVariant}/>
    </TableCell>
  )
}

export default StatusTemplate;