import React, { Component } from 'react';
import { Grid, Card, CardHeader, CardContent, Avatar } from '@material-ui/core';
import {Typography} from '../../../../components';
import {withStyles} from '@material-ui/styles';
import {getMongoCustomerData} from '../../../../indexBackend';

const styles = theme => ({
  card: {
    padding: theme.spacing(2)
  },
  cardContent: {
    paddingTop: "1.5rem"
  },
  item: {
    marginBottom: theme.spacing(2.25),
    alignItems: "center",
    display: "flex",
    '&:last-of-type': {
      marginBottom: "0"
    }
  },
  icon: {
    color: theme.palette.common.white,
    width: "40px",
    height: "40px",
    borderRadius: ".25rem",
    fontWeight: 500,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
    textAlign: "center!important",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  text: {
    display: "flex",
    flexDirection: "column",
  },
  secondaryText: {
    color: theme.palette.text.secondary
  },
  avatar: {
    height: '32px',
    width: '32px'
  }
})

class RegisteredCustomers extends Component {  
  render() {
    const {classes, customers, customerStats} = this.props;

    const customerList = [...customers].map(x => {
      const customer = [...customerStats].find(c => x._id === c.customer_id);
      return {
        ...x,
        usage: customer.usage
      }
    }).sort((a,b) => {
      if(a.usage > b.usage) {
        return -1
      } else if(a.usage < b.usage) {
        return 1
      } else {
        return 0
      }
    }).splice(0, 5)

    return (
      <Grid 
        item
        xs={12}
        md={4}
        lg={3}
      >
        <Card elevation={0} variant="outlined" className={classes.card}>  
          <CardHeader
            title={(<Typography variant="body1" bold>Top Customers</Typography>)}
            subheader={(<Typography variant="body2">Customers with the most Surveys</Typography>)}
            disableTypography
          />
          <CardContent className={classes.cardContent}>
            {
              customerList ? customerList.map((x, i) => (
                <div key={x._id} className={classes.item}>
                  <div className={[classes.icon].join(' ')}>
                    <Avatar variant={x.logo ? "square" : "circle"} className={classes.avatar} src={x.logo}/>
                  </div>
                  <div className={classes.text}>
                    <Typography variant="cell">
                      {x.companyName}
                    </Typography>
                    <Typography variant="cell" className={classes.secondaryText}>
                      {x.industry}
                    </Typography>
                  </div>
                </div>
              )) : null
            }
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(RegisteredCustomers);