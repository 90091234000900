import React, {useState} from 'react';
import propTypes from 'prop-types';
import {Avatar, TableCell} from '@material-ui/core';
import {Typography} from '../../../../components';
import {withStyles} from '@material-ui/styles';

const styles = theme => ({
  imageCell: {
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: "nowrap"
  },
  image: {
    marginRight: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    maxHeight: '40px',
    justifyContent: "center"
  },
  imageText: {
    lineHeight: '20px',
  },
  imageSubText: {
    color: theme.palette.text.secondary,
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  avatar: {
    width: "32px",
    maxHeight: "32px",
    minHeight: "32px"
  }
})

const ImageText = props => {
  const {classes, imageUrl, text, shape} = props;

  const variant = shape ? shape : 'circular';

  return (
    <TableCell align='right'>
      <div className={classes.imageCell}>
        <div className={classes.image}>
          {
            props.altType === 'text' && !imageUrl 
            ? <Avatar size="small" className={classes.avatar}>Aa</Avatar>
            : <Avatar size="small" variant={variant} src={imageUrl} className={classes.avatar}/>
          }
        </div>
        <div className={classes.text}>
          <div className={classes.textWrapper}>
            <Typography variant="cell" className={classes.imageText}>
              {text.primary}
            </Typography>
          </div>
          {
            text.secondary ? <div>
              <Typography variant="cell" className={[classes.imageText, classes.imageSubText].join(" ")}>
                {text.secondary}
              </Typography>
            </div> : null
          }
        </div>
      </div>
    </TableCell>
  )
}

ImageText.propTypes = {
  imageUrl: propTypes.string,
  text: propTypes.object.isRequired
} 

export default withStyles(styles)(ImageText);