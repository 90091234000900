import React, { Component } from 'react';
import '../../css/App.css';
import { Alerts, Button, Table } from '../../components';
import { getSurveysFromMongo, sendSurveyToEndpoint, getMongoSurveyConfig, updateMongoSurvey } from '../../indexBackend';
const axios = require('axios')
const { v4: uuidv4 } = require('uuid');

class ocsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false, checked: false, companyName: '', tableData: {
        labels: [],
        rows: []
      }
    };
  }


  async componentDidMount() {
    this.state.uniqueId = uuidv4()
    let token = localStorage.getItem('token')
    if (token === null) {
      this.props.history.push('/login');
    }
    else {
      await axios({
        url: '/api/checkLogin',
        method: 'POST',
        headers: { 'content-type': 'application/json', 'Authorization': 'Bearer '+token },
      }).then((data) => {
        if (new Date(data.data) < new Date()) {
          this.props.history.push('/login');
        }
        else {
          this.getResultsData()
        }
      })
        .catch(err => {
          this.props.history.push('/login');
        })
    }
  }

  async resendRequest(btn) {
    let thisResponse = this.state[btn]
    let _id = thisResponse._id
    let responseUrl = thisResponse.request.originalUrl
    let comment = ''
    let endpointData = await getMongoSurveyConfig(comment, responseUrl, _id)
    if (endpointData.status === 200) {
      let endpointResponse = await sendSurveyToEndpoint(endpointData.mongoData)
      let mongoResponse = await updateMongoSurvey(endpointResponse)
      if (mongoResponse.status === 200) {
        // 'Display success message from mongo data to end user'
        Alerts({ type: 'success', text: 'Resend successful' });
        this.getResultsData()
      }
      else {
        // 'respond with error saying data couldnt be submitted but display custom error to user'
        Alerts({ type: 'error', text: 'Resend not completed' });
      }
    }
    else {
      Alerts({ type: 'error', text: 'Resend not completed' });
    }
  }




  async getResultsData() {
    let customerData = await getSurveysFromMongo()
    if (customerData.status === 200) {
      let labels
      let rows = []
      let tableData = {
        labels: [
          {
            label: 'Customer Name',
            sortable: false
          },
          {
            label: 'Time Sent',
            sortable: false
          },
          {
            label: 'Response Code',
            sortable: false
          },
          {
            label: 'Response',
            sortable: false
          },
          {
            label: 'Original Url',
            sortable: false
          },
          {
            label: 'Resend',
            sortable: false
          }
        ]
      }
      for (let i = 0; i < customerData.data.data.length; i++) {
        let id = customerData.data.data[i]._id
        this.setState({ [id]: customerData.data.data[i] })
        let customerName = customerData.data.data[i].companyName
        let timestamp = customerData.data.data[i].timestamp;
        let responseCode = customerData.data.data[i].response.statusCode;
        let originalUrl = customerData.data.data[i].request.originalUrl;
        let response = JSON.stringify(customerData.data.data[i].response.body);
        rows.push(
          {
            id: id,
            cells: [
              customerName,
              timestamp,
              responseCode,
              {
                sortBy: false,
                type: 'custom',
                options: {
                  element: (
                    <React.Fragment>
                      <div style={{ maxWidth: '80vh', overflow: 'scroll' }}>
                        {response}
                      </div>
                    </React.Fragment>
                  )
                }
              },
              {
                sortBy: false,
                type: 'custom',
                options: {
                  element: (
                    <React.Fragment>
                      <div style={{ maxWidth: '40vh', overflow: 'scroll' }}>
                        {originalUrl}
                      </div>
                    </React.Fragment>
                  )
                }
              },
              {
                sortBy: false,
                type: 'custom',
                options: {
                  element: (
                    <React.Fragment>
                      <div>
                        <Button onClick={() => this.resendRequest(id)} id={id}>Resend</Button>
                      </div>
                    </React.Fragment>
                  )
                }
              }
            ]
          }
        )
      }
      tableData.rows = rows
      this.setState({ tableData: tableData })
    }
    else {
      Alerts({ type: 'error', text: 'Customer Data could not be shown' });
    }
  }

  render() {
    const { classes, step, open, close, exit, stepHandler, languagePack, enabledLanguages, allLanguages } = this.props;
    return (
      <React.Fragment>
        <Table
          tableData={this.state.tableData}
          recordsPerPage={4}
        />
      </React.Fragment>
    )
  }
}

export default ocsDashboard;