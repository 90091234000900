import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import {withTheme} from '@material-ui/styles';

class Checkbox extends Component {
  render() {
    const {theme, color = "info", ...rest} = this.props;

    const themeColor = theme.palette[color].main;

    return (
      <MuiCheckbox
        icon={
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="2" fill="inherit"/>
            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke={themeColor} strokeOpacity="0.5"/>
          </svg>
        }
        checkedIcon={
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="2" fill={themeColor}/>
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="3" width="12" height="10">
              <path d="M5.86337 10.5832L3.55004 8.26985C3.42548 8.14502 3.25638 8.07486 3.08004 8.07486C2.90369 8.07486 2.73459 8.14502 2.61004 8.26985C2.35004 8.52985 2.35004 8.94985 2.61004 9.20985L5.39671 11.9965C5.65671 12.2565 6.07671 12.2565 6.33671 11.9965L13.39 4.94319C13.65 4.68319 13.65 4.26319 13.39 4.00319C13.2655 3.87835 13.0964 3.8082 12.92 3.8082C12.7437 3.8082 12.5746 3.87835 12.45 4.00319L5.86337 10.5832Z" fill="#09101D"/>
            </mask>
            <g mask="url(#mask0)">
              <rect width="16" height="16" fill="white"/>
            </g>
          </svg>
        }
        style={{color: theme.palette[color].main}}
        {...rest}
      />
    );
  }
}

Checkbox.propTypes = {
  color: PropTypes.string
}

export default withTheme(Checkbox);