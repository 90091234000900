import React, { Component } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Grid, FormControlLabel} from '@material-ui/core';
import {Typography, Button, TextInput, Checkbox} from '../../../../../../../../components';
import {Delete, Add} from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';


const styles = theme => ({
  button: {
    maxHeight: "48px",
    maxWidth: "48px",
    minWidth: "48px",
    marginLeft: theme.spacing(1)
  }
})

class EditSurveyDialog extends Component {
  render() {
    const {classes, open, values, close, fieldHandler, save, exit, addCustomField, deleteCustomField, cfFieldHandler} = this.props;
    return (
      <Dialog open={open} maxWidth="sm" fullWidth onClose={close} onExit={exit}>
        <DialogTitle disableTypography>
          <Typography variant="h5">
            Survey Configuration
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Fill in all the details below to create a new Survey
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput fullWidth label="Survey Name" value={values?.name} name="name" onChange={fieldHandler}/>
            </Grid>
            <Grid item xs={12}>
              <TextInput fullWidth label="Description" value={values?.description} name="description" onChange={fieldHandler}/>
            </Grid>
            <Grid item xs={12}>
              <TextInput fullWidth label="Endpoint" value={values?.request?.endpoint} name="endpoint" onChange={fieldHandler}/>
            </Grid>
            <Grid item xs={12}>
              <TextInput fullWidth label="Type" value="OneClick Survey" name="type" disabled onChange={fieldHandler}/>
            </Grid>
            <Grid item xs={12}>
              <TextInput fullWidth label="Survey UID" value={values?._id} name="uid" disabled onChange={fieldHandler}/>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={<Typography variant='body2' bold>Custom Fields</Typography>}
                control={
                  <Checkbox
                    name="customField"
                    onChange={fieldHandler}
                    checked={values?.request?.body.length > 3}
                  />
                }
              />
            </Grid>
            <>
            {
                values?.request.body.length > 3 ? 
                  values.request.body.filter(x => !['recordId', 'score', 'comment'].includes(x.internalName)).map((x, i) => {
                    return (
                    <Grid key={i} item xs={12} style={{display: 'flex', flexDirection: "row"}}>
                      <TextInput fullWidth label="Custom Field " value={x.internalName} name={"customfield-" + i} onChange={cfFieldHandler}/>
                      <div style={{maxWidth: "96px", display: "flex"}}>
                        <Button key={"button-" + i} className={classes.button} color='error' onClick={deleteCustomField} id={"customfield-delete-" + i}>
                          <Delete fontSize="small" style={{paddingTop: "4px"}}/>
                        </Button>
                        <Button className={classes.button} color='info' onClick={addCustomField}>
                          <Add fontSize="small" style={{paddingTop: "4px"}}/>
                        </Button>
                      </div>
                    </Grid>
                    )
                  })
                : null 
              }
            </>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={close}>
            Cancel
          </Button>
          <Button disableElevation fullWidth color="info" variant="contained" onClick={save}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(EditSurveyDialog);