import supported from '../translation/supported.json';
// import integrations from '../views/appstore/integrations.json';
import engbLangauge from '../translation/static/en-GB.json'

const initState = {
  languagePack: engbLangauge,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'SETLANGUAGE':
      return {
        ...state,
        uiLanguage: action.payload.uiLanguage,
        languagePack: action.payload.languagePack,
        integrationLanguagePacks: action.payload.integrationLanguagePacks,
        languages: action.payload.languages
      };
    case 'SETENABLEDLANGUAGES': 
      return {
        ...state,
        enabled: action.payload.enabledLanguages
      }
    default:
      return state;
  }
}

export default reducer;