const axios = require('axios')

function checkForRequiredFields(combined) {
    let params = {
        recordid: false,
        surveyid: false,
        score: false
    }
    for (let i = 0; i < combined.length; i++) {
        let currentObject = combined[i]
        for (const [key, value] of Object.entries(currentObject)) {
            if (key === 'surveyId') {
                if (value !== '') {
                    params.surveyid = true
                }
            }
            if (key === 'recordId') {
                if (value !== '') {
                    params.recordid = true
                }
            }
            if (key === 'score') {
                if (value !== '') {
                    params.score = true
                }
            }
        }
    }

    return Object.values(params).includes(false);
}

module.exports = {
    updateSurveyStatistics: async function (id, usage) {
        let response
        let surveyId = id
        let usageNumber = Number(usage) + 1
        let usageObject = {
            "usage": usageNumber
        }
        await axios({
            url: 'api/surveyUsage/' + surveyId,
            method: 'put',
            data: { usageObject },
        })
            .then(async function (res) {
                response = res
            })
            .catch(function (err) {
                response = err.response;
            });
        return response
    },
    getMongoSurveyConfig: async function (comment = null, data = '', _id) {
        // 1
        let urlParams
        let fullUrl
        if (data !== '') {
            let url = new URL(data)
            urlParams = url.search;
            fullUrl = url.href
        }
        else {
            urlParams = window.location.search;
            fullUrl = window.location.href
        }
        let params = new URLSearchParams(urlParams);
        let parameters = {}
        let response
        let mongoData
        for (let name of params.keys()) {
            let value = params.get(name)
            name = name.toLowerCase()
            parameters[name] = value;
        }
        parameters['comment'] = comment;
        parameters.fullUrl = fullUrl
        parameters._id = _id
        // if (parameters.surveyId === undefined || parameters.responseId === undefined) {
        //     response = {
        //         status: 404,
        //         data: {
        //             data: "Missing required fields",
        //             statusText: 'Error',
        //             status: 401
        //         }
        //     }
        // }
        // else {
            await axios({
                url: '/api/surveyResponse/' + parameters.surveyid,
                method: 'post',
                data: parameters
            })
                .then(async res => {
                    response = res;
                })
                .catch(err => {
                    response = err
                })
        // }
        return response
    },
    resendSurvey: async function (comment = null, data = '', _id) {
        // 1
        let urlParams
        let fullUrl
        if (data !== '') {
            let url = new URL(data)
            urlParams = url.search;
            fullUrl = url.href
        }
        else {
            urlParams = window.location.search;
            fullUrl = window.location.href
        }
        let params = new URLSearchParams(urlParams);
        let parameters = {}
        let response
        let mongoData
        for (let name of params.keys()) {
            let value = params.get(name)
            name = name.toLowerCase()
            parameters[name] = value;
        }
        parameters['comment'] = comment;
        parameters.fullUrl = fullUrl
        parameters._id = _id
        // if (parameters.surveyId === undefined || parameters.responseId === undefined) {
        //     response = {
        //         status: 404,
        //         data: {
        //             data: "Missing required fields",
        //             statusText: 'Error',
        //             status: 401
        //         }
        //     }
        // }
        // else {
            await axios({
                url: '/api/surveyResend/' + parameters.surveyid,
                method: 'post',
                data: parameters,
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
            })
                .then(async res => {
                    response = res;
                })
                .catch(err => {
                    throw err
                })
        // }
        return response
    },
    createMongoCustomer: async function (newAccountDetails) {
        let response
        await axios({
            url: 'api/customer',
            method: 'post',
            data: newAccountDetails,
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        })
            .then(async function (res) {
                response = res
            })
            .catch(function (err) {
                throw err;
            });
        return response
    },
    getMongoCustomerData: async function () {
        let res
        await axios({
            url: '/api/customer',
            method: 'get',
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        })
            .then(async function (response) {
                res = response
            })
            .catch(function (error) {
                res = error;
            });
        return res
    },
    sendSurveyToEndpoint: async function (mongoData) {
        // 2
        let response
        await axios({
            url: '/api/endpoint',
            method: 'post',
            data: mongoData
        })
            .then(async function (res) {
                response = res
            })
            .catch(function (err) {
                response = err.response;
            });
        return response
    },
    sendSurveyResultToMongo: async function (mongoData, prevResponse) {
        // 3
        let response
        mongoData.prevResponse = prevResponse
        await axios({
            url: 'api/survey',
            method: 'post',
            data: mongoData
        })
            .then(async function (res) {
                response = res
            })
            .catch(function (err) {
                response = err.response;
            });
        return response
    },
    getSurveysFromMongo: async function (params) {
        // 3
        let response
        const searchParams = new URLSearchParams(params);
        await axios({
            url: `/api/survey?${searchParams.toString()}`,
            method: 'get',
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        })
            .then(async function (res) {
                response = res
            })
            .catch(function (err) {
                response = err.response;
            });
        return response
    },
    updateMongoSurvey: async function (mongoData) {
        // 3
        let response
        await axios({
            url: '/api/survey/' + mongoData.result_id,
            method: 'put',
            data: mongoData,
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        })
            .then(async function (res) {
                response = res
            })
            .catch(function (err) {
                response = err.response;
            });
        return response
    },
    deleteCustomer: async (id) => {
        const res = await axios({
            url: `/api/customer/${id}`,
            method: 'delete',
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        }).catch(err => {
            throw err;
        })

        return res;
    },
    editCustomer: async (id, payload) => await axios({
        url: `/api/customer/${id}`,
        method: 'put',
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') },
        data: payload
    }),
    getSurveySchemas: async () => {
        const res = await axios({
            url: `/api/surveySchema`,
            method: 'get',
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        }).catch(err => {
            throw err;
        })

        return res;
    },
    editSurveySchemas: async (pl) => {
        const res = await axios({
            url: `/api/surveySchema/${pl._id}`,
            method: 'put',
            data: pl,
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        }).catch(err => {
            throw err;
        })

        return res;
    },
    getStatistics: async (month = 3) => {
        let url = '/api/statistics/' + month + ''
        const res = await axios({
            url: url,
            method: 'get',
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        }).catch(err => {
            throw err;
        })
        return res;
    },
    deleteSurvey: async (id) => {
        const res = await axios({
            url: `/api/survey/` + id,
            method: 'delete',
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        }).catch(err => {
            throw err;
        })
        return res;
    },
    deleteSurveySchema: async (id) => {
        const res = await axios({
            url: `/api/surveySchema/` + id,
            method: 'delete',
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        }).catch(err => {
            throw err;
        })
        return res;
    },
    createSurveySchema: async (data) => {
        return await axios({
            url: `/api/surveySchema`,
            method: 'post',
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') },
            data
        })
    }
}
