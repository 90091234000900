import React, { Component } from 'react';
import {Grid, Card, CardHeader, CardContent} from '@material-ui/core';
import Chart from 'react-apexcharts';
import {withStyles} from '@material-ui/styles';
import moment from 'moment';
import {Typography} from '../../../../components';

const styles = theme => ({
  card: {
    padding: theme.spacing(2),
    paddingBottom: '0'
  }
})


class RecentUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usage: []
    }
  }
  

  componentDidMount() {
    this.calcUsage() 
  }
  
  calcUsage = async () => {
    const usage = this.props.usage ? [...this.props.usage] : []

    const stats = {

    }

    const cd = []

    await Promise.all(usage.map(x => {
      const date = moment.utc(x.timestamp).startOf('day')
      if(x.timestamp && date) {
        if(stats[date]) {
          stats[date] = stats[date] + 1
        } else {
          stats[date] = 1
        }
      }
    })).then(Object.keys(stats).sort((a, b) => {
      return new Date(a) - new Date(b);
    }).map(x => {
      cd.push([x, stats[x]])
    }))

    this.setState(state => ({
      ...state,
      usage: cd
    }))
  }


  render() {
    const {classes} = this.props;

    const today = new Date(moment.utc().startOf('day'));
    const lastMonth = new Date(moment.utc().subtract(30, 'd').startOf('day'));
    // const lastMonth = moment().subtract(30, 'd').format('x');

    const options = {
      chart: {
        type: 'area',
        fontFamily: 'Poppins, sans-serif',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        min: lastMonth.getTime(),
        max: today.getTime(),
      },
      yaxis: {
        decimalsInFloat: 0,
      },
      colors: [
        "rgb(3, 169, 252)"
      ]
    }
    
    return (
      <Grid 
        item
        xs={12}
        md={8}
        lg={9}
      >
        <Card elevation={0} variant="outlined" className={classes.card}>
          <CardHeader
            title={(<Typography variant="body1" bold>Usage</Typography>)}
            subheader={(<Typography variant="body2">Snapshot of Usage over the last 30 days</Typography>)}
            disableTypography
          />
          <CardContent>
            <Chart
              options={options}
              series={[
                {
                  name: 'Requests',
                  data: this.state.usage
                },
              ]}
              type="area"
              height={295}
            />
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(RecentUsage);