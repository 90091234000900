import React, { Component } from 'react';
import {
  Table as MuiTable, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  TableContainer, 
  TableSortLabel,
} from '@material-ui/core';
import {
  Pagination
} from '@material-ui/lab'
import {withStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import {Typography} from '../../components';
import {
  Actions,
  ImageText,
  Chips,
  Status,
  Checkbox,
  Text
} from './templates';
import { PermDeviceInformationSharp } from '@material-ui/icons';

const styles = theme => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  paginationContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2)
  },
  selectedRow: {
    backgroundColor: theme.palette.info.light
  }
})

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOrder: this.props.defaultSortOrder || 'asc',
      orderBy: this.props.defaultSort || null,
      page: 0,
      recordsPerPage: this.props.recordsPerPage
    }
  }

  componentDidUpdate() {
    const { tableData } = this.props;
    const totalPages = Math.ceil(tableData.rows.length / this.state.recordsPerPage);
    if(totalPages < (this.state.page + 1) && totalPages !== 0) {
      this.setState(state => ({
        ...state,
        page: (totalPages - 1)
      }))
    }
  }
  
  sortHandler = (index) => {
    const isAsc = this.state.orderBy === index && this.state.sortOrder === 'asc';
    this.setState(state => ({
      ...state,
      sortOrder: isAsc ? 'desc' : 'asc',
      orderBy: index
    }))
  }

  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b)
      : (a, b) => -this.descendingComparator(a, b);
  }

  descendingComparator = (a, b) => {
    if (b < a) {
      return -1;
    }
    if (b > a) {
      return 1;
    }

    return 0;
  }

  sortTable = (array, comparator) => {
    const sortIndex = this.state.orderBy || 0;
    if(sortIndex === -1) {
      return array;
    }

    const indexedArray = array.map((x, i) => ({ i, ...x }));
    
    indexedArray.sort((a, b) => {
      let sortValA = a.cells[sortIndex].sortBy ? a.cells[sortIndex].sortBy : a.cells[sortIndex];
      let sortValB = b.cells[sortIndex].sortBy ? b.cells[sortIndex].sortBy : b.cells[sortIndex];
      
      if(typeof sortValA === 'string'){
        sortValA = sortValA.toLowerCase();
      }
      
      if(typeof sortValB === 'string'){
        sortValB = sortValB.toLowerCase();
      }

      const order = comparator(sortValA, sortValB);
      
      if (order !== 0) return order;
      
      return a.i > b.i;
    })

    return indexedArray;
  }

  paginationHandler = (event, page) => {
    this.setState(state => ({
      ...state,
      page: page - 1
    }))
  }

  getCellTemplate = (type, options, key) => {
    switch (type) {
      case "actions":
        return <Actions button={options.button} actions={options.actions} click={options.click} key={key} heading={options.heading}/>;
      case "imagetext": 
        return <ImageText imageUrl={options.imageUrl} text={options.text} altType={options.altType} shape={options.shape} key={key} {...options}/>;
      case "text": 
        return <Text text={options.text} key={key} {...options}/>;
      case "chips": 
        return <Chips chips={options.chips} key={key} {...options}/>;
      case "status": 
        return <Status text={options.text} color={options.color} colorVariant={options.colorVariant} key={key}/>;
      case "checkbox": 
        return <Checkbox {...options} key={key}/>;
      case "custom": 
        return  <TableCell key={"autocomplete-"+options.key}>{options.element}</TableCell>;
      default:
        break;
    }
  }

  render() {
    const {classes, dense, sortable, tableData, selectedArr, selectionHandler} = this.props;        
    if (tableData === null)
      return null;
    const spliceIndex = [0, tableData.rows.length]
    if(this.state.recordsPerPage) {
      spliceIndex[0] = this.state.page * this.state.recordsPerPage;
      spliceIndex[1] = this.state.page * this.state.recordsPerPage + this.state.recordsPerPage;
    }

    const totalPages = Math.ceil(tableData.rows.length / this.state.recordsPerPage);

    return (
      <React.Fragment>
        <TableContainer>
          <MuiTable size={dense ? "small" : "medium"}>
            <TableHead>
              <TableRow>
                {
                  selectedArr && selectionHandler ? 
                  this.getCellTemplate('checkbox', {onCheck: () => selectionHandler(), checked: tableData.rows.length === selectedArr.length}, 'selectAll') 
                  : null 
                }
                {tableData.labels ? tableData.labels.map((x, index) => {

                  return(
                    <TableCell 
                      key={`heading-${index}`} 
                      id={`heading-${index}`} 
                      sortDirection={this.state.orderBy === index ? this.state.SortOrder : false}
                    >
                      { x.sortable ? 
                      <TableSortLabel 
                        active={this.state.orderBy === index}
                        direction={this.state.orderBy === index ? this.state.sortOrder : 'asc'}
                        onClick={() => this.sortHandler(index)}
                      > 
                        <Typography variant='tableHeader' bold>
                          {x.label}
                        </Typography>

                        {this.state.orderBy === index ? (
                          <span className={classes.visuallyHidden}>
                            {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel> : x.label}
                    </TableCell>
                  )
                }) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.sortTable(tableData.rows, this.getComparator(this.state.sortOrder, this.state.orderBy))
                .slice(spliceIndex[0], spliceIndex[1])
                .map((row, index) => {
                  return(
                    <TableRow className={selectedArr?.includes(row.id) ? classes.selectedRow : ""} key={index} onClick={row.onClick} style={{cursor: row.onClick ? 'pointer' : null}}>
                      {
                        selectedArr && selectionHandler ? 
                        this.getCellTemplate('checkbox', {onCheck: () => selectionHandler(row.id), checked: selectedArr.includes(row.id)}, 'Checkbox-'+row.id) 
                        : null 
                      }
                      {row.cells.map((cell, cellIndex)=> {
                        if(cell.type) {
                          return this.getCellTemplate(cell.type, cell.options, row.id+"-"+cellIndex)
                        } else {
                          return (
                            <TableCell key={row.id+"-"+cellIndex}>
                              <Typography variant="cell">
                                {cell.toString()}
                              </Typography>
                            </TableCell>
                          )
                        }
                      })}
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </MuiTable>
        </TableContainer>
        {
          this.state.recordsPerPage ? 
          <div className={classes.paginationContainer}>
            <Pagination
              count={totalPages} 
              variant="outlined" 
              shape="rounded"
              onChange={this.paginationHandler}
            /> 
          </div> : null
        }
      </React.Fragment>
    );
  }
}

Table.propTypes = {
  dense: PropTypes.bool, 
  tableData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      sortable: PropTypes.bool
    })),
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        cells: PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.object
          ])
        )
      })
    )
  }).isRequired, 
  selectedArr: PropTypes.array, 
  selectionHandler: PropTypes.func,
  defaultSort: PropTypes.string,
  defaultSortOrder: PropTypes.string,
  recordsPerPage: PropTypes.number
}

export default withStyles(styles)(Table);
