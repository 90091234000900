import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTheme, withStyles} from '@material-ui/styles';

const styles = (theme) => ({
  statusIcon: {
    padding: 0,
    margin: 'auto',
    "& li": {
      listStyle:"none",
      "&:before": {
        listStyle:"none",
        content: '"\\25CF"',
        marginRight: ".5rem",
        "fontSize": "1.2em",
        verticalAlign: "text-bottom",
        fontFamily: "mono",
        lineHeight: 1.2,
      }
    }
  }
});

class Status extends Component {
  render() {
    const {text, color = "grey", classes, theme} = this.props;
    const { colorVariant = color === "text" ? "primary" : color === "grey" ? "700" : "main" } = this.props;
    
    return (
      <ul className={classes.statusIcon} style={{ color: theme.palette[color][colorVariant] }}>
        <li>{text}</li>
      </ul>
    );
  }
}

Status.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string
}

export default withTheme(withStyles(styles)(Status));