import React, { Component } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core';
import {withTheme} from '@material-ui/styles';
import {Typography, Button} from '../';

class SimpleDialog extends Component {
  render() {
    const {theme, open, close, heading, content, submit, submitText, submitColor = 'error'} = this.props

    return (
      <Dialog open={open} onClose={close} fullWidth maxWidth='xs'>
        <DialogTitle disableTypography>
          <Typography variant='h5'>
            {heading}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
        <DialogActions>
          <Button disableElevation fullWidth size="large" onClick={close}>
            Close
          </Button>
          <Button disableElevation fullWidth size="large" variant="contained" color={submitColor} onClick={submit}>
            {submitText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTheme(SimpleDialog);