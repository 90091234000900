import Noty from 'noty';
import '../../../node_modules/noty/lib/noty.css';
import '../../../node_modules/noty/lib/themes/sunset.css';
import PropTypes from 'prop-types';

const Alerts = ({text, type}) => {
  new Noty({
    type: type,
    timeout: type === 'error' ?  false : 5000,
    theme: 'sunset',
    text: text
  }).show();
}

Alerts.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export default Alerts;