import React from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';
const RouteWithLayout = props => {
  const {layout: Layout, component: Component, ...rest} = props;

  return (
    <Route
      {...rest}
      render={x => (
        <Layout>
          <Component {...x}/>
        </Layout>
      )}
    />
  )
}

RouteWithLayout.propTypes = {
  Layout: PropTypes.element,
  Component: PropTypes.element
}

export default RouteWithLayout;