import React, { Component } from 'react';
import {Backdrop, CircularProgress} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
})

class LoadingSpinner extends Component {
  render() {
    const {classes} = this.props;
    return (
      <Backdrop open={true} className={classes.backdrop}>
        <CircularProgress color="inherit"/>
      </Backdrop>
    );
  }
}

export default withStyles(styles)(LoadingSpinner);