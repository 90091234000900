import React from 'react';
import { AppBar } from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import {Typography} from '../../../../components';

const styles = theme => ({
  topbar: {
    height: "55px",
    width: "100vw !important",
    paddingLeft: "90px !important",
    backgroundColor: "#fff!important",
    borderBottom: "1px solid #dee2e6!important",
    zIndex: "1020!important",
    top: "0!important",
    color: "#000!important",
    paddingRight: theme.spacing(2)
  },
  content: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  heading: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  typography: {
    marginBottom: "0!important"
  },
  buttons: {
    display: "flex",
    marginTop: "auto!important",
    marginBottom: "auto!important"
  },
  languageButton: {
    width: "25px",
    height: "25px",
    marginTop: "auto",
    marginBottom: "auto",
    paddingLeft: "12px",
    cursor: "pointer"
  },
  feedback: {
    color: theme.palette.primary.main
  },
  support: {
    color: theme.palette.error.main
  },
  menuItem: {
    fontWeight: 400,
    color: "#212529",
    textAlign: "inherit",
    whiteSpace: "nowrap",
    fontSize: "16px"
  },
  menuItemImg: {
    width: "20px",
    height: "20px"
  }
})

class Topbar extends React.Component {
  render() {
    const {title, classes} = this.props;
    return ( 
      <AppBar className={classes.topbar}>
        <div className={classes.content}>
          <div className={classes.heading}>
            <Typography variant="button" className={classes.typography}>
              {title}
            </Typography>
          </div>
        </div>
      </AppBar>
    )
  }
}

export default withStyles(styles)(Topbar);