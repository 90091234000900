import React, { Component } from 'react';
import {Tooltip} from '@material-ui/core';
import PropTypes from 'prop-types';

class CustomTooltip extends Component {
  render() {
    const {children, placement, text, ...rest} = this.props;
    if(!text && !placement) {
      return children
    }
    return (
      <Tooltip title={text} placement={placement ? placement : 'bottom'} arrow interactive {...rest}>
        {children}
      </Tooltip>
    );
  }
}

CustomTooltip.propTypes = {
  children: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  placement: PropTypes.string
}

export default CustomTooltip;