import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Grid, FormControlLabel } from '@material-ui/core';
import {Button, Typography, TextInput, Checkbox} from '../../../../components';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import momentUtils from '@date-io/moment';
import moment from 'moment';

class EditCustomerDialog extends Component {
  render() {
    const {open, close, save, customer, customerFields, fieldHandler} = this.props;

    return (
      <Dialog open={open} onClose={close}>
        <DialogTitle disableTypography>
          <Typography variant="h5">
            Edit Customer
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                All fields below are editable
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="companyName"
                label="Company Name"
                onChange={fieldHandler}
                value={customer?.companyName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="industry"
                label="Industry"
                onChange={fieldHandler}
                value={customer?.industry}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="keyContact-name"
                label="Key Contact"
                onChange={fieldHandler}
                value={customer?.keyContact.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="keyContact-email"
                label="Key Contact Email Address"
                onChange={fieldHandler}
                value={customer?.keyContact.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="hostname"
                label="Hostname"
                onChange={fieldHandler}
                value={customer?.hostname}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="logo"
                type='file'
                label="Upload Logo"
                InputLabelProps={{ shrink: true }}
                onChange={fieldHandler}
              />
            </Grid>
            <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="trial"
                  checked={customer?.trial}
                  onChange={fieldHandler}
                />
              }
              label={<Typography variant='body2' bold>Trial (provides 30-days access)</Typography>}
            />
            </Grid>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={momentUtils}>
                <DatePicker
                  label="Expiry Date"
                  format="DD/MM/yyyy"
                  value={moment(customer ? customer.expiry : null)}
                  onChange={(val) => fieldHandler(null, val)}
                  TextFieldComponent={ (props) => <TextInput 
                    {...props}
                    InputLabelProps={{
                      shrink: true,
                      color: 'secondary'
                    }} 
                  />
                }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="uid"
                label="UID"
                onChange={fieldHandler}
                value={customer?._id}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={close}>
            Cancel
          </Button>
          <Button fullWidth disableElevation color='info' variant='contained' onClick={() => save(customer._id, null)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditCustomerDialog;