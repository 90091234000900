import React, { Component } from 'react';
import {AppBar, Toolbar, Tabs, Tab, Box} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import {ArrowDropDown} from '@material-ui/icons';
import { Button, Typography } from '../../../../components';
import { CompletedSurvey, Survey } from './content';


const styles = theme => ({
  appbar: {
    backgroundColor: 'transparent',
    height: '50px',
    paddingLeft: theme.spacing(2), 
    paddingRight: theme.spacing(2), 
    marginBottom: theme.spacing(2),
    zIndex: 1010
  },
  tabs: {
    color: theme.palette.neutral[0],
    opacity: 1
  },
  tab: {
    minWidth: "0px"
  }
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      width={1}
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {children}
    </Box>
  );
}

class Navbar extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      tab: 0
    }
  }
  
  tabHandler = (event, val) => {
    this.setState(state => ({
      ...state,
      tab: val
    }))
  }

  a11yProps = index => {
    return {
      id: `toolbar-tab-${index}`,
      'aria-controls': `toolbar-tabpanel-${index}`,
    };
  }

  render() {
    const {classes} = this.props;
    
    return (
      <>
        <AppBar className={classes.appbar} position='static'>
          <Toolbar disableGutters>
            <Tabs value={this.state.tab} className={classes.tabs} onChange={this.tabHandler}>
              <Tab label={<Typography variant="body1" bold>Completed Surveys</Typography>} {...this.a11yProps(0)} />
              <Tab label={<Typography variant="body1" className={classes.tab} bold>Surveys</Typography>} {...this.a11yProps(1)} />
            </Tabs>
          </Toolbar>
        </AppBar>
        <TabPanel value={this.state.tab} index={0}>
          <CompletedSurvey key='completedSurvey'/>
        </TabPanel>
        <TabPanel value={this.state.tab} index={1}>
          <Survey key='survey'/>
        </TabPanel>
      </>
    );
  }
}

export default withStyles(styles)(Navbar);