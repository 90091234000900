import { Box, Card, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {ErrorOutlineRounded, CheckCircleOutlineRounded} from '@material-ui/icons';
import React, { Component } from 'react';
import '../../css/App.css';
import { Button, TextInput, Typography, LoadingSpinner } from '../../components';
import { getMongoSurveyConfig, updateMongoSurvey } from '../../indexBackend';
import logo from '../../images/OcsLogo.png';

const styles = theme => ({
  card: {
    boxShadow: theme.elevations.fluffy[2],
    padding: theme.spacing(3),
    verticalAlign: "middle", 
    textAlign: "center", 
    height: 'fit-content', 
    borderRadius: "16px",
    maxWidth: "363px"
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: "5.815rem",
    marginBottom: theme.spacing(1)
  },
  errorIcon: {
    marginBottom: theme.spacing(1),
    fontSize: "5.815rem",
    color: theme.palette.error.main
  },
  spacer: {
    marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(4.0625)
  }
})

class ocsFrontEnd extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      buttonDisabled: false, 
      comment: '',
      endpointResponse: '', 
      boxHidden: 'none',
      responseData: null,
      responseError: null,
      submitted: false
    };
  }

  async componentDidMount() {
    this.displayResponse(null)
  }

  updateComment = async () => {
    this.displayResponse(this.state.comment)

    this.setState(state => ({
      ...state,
      submitted: true
    }))
  }

  setResponse = (response, submitted = false) => {
    const comment = response?.request.body.find(x => x.internalName === 'comment').value
    this.setState(state => ({
      ...state,
      responseData: response || state.responseData,
      comment,
      submitted: submitted ? comment !== "" : state.submitted
    }))
  }
  
  async displayResponse(comment) {
    let mongoResponse = await getMongoSurveyConfig(comment)
    if (mongoResponse.status === 200) {
      if (mongoResponse.status === 200) {
        this.setResponse(mongoResponse.data.data, true);
        this.setState({
          topGap: '12vh',
          returnIcon: <CheckCircleOutlineRounded className={this.props.classes.successIcon} />,
          buttonDisabled: false,
          boxHidden: 'block',
          topResponse: 'Thank you for your response',
          response: <Typography variant="body1" style={{marginBottom: "32.5px"}}>
            {'If you’d like to leave additional comments, please use the space below and click submit.'}
          </Typography>
        });
      } else {
        // 'respond with error saying data couldnt be submitted but display custom error to user'
        this.setState({
          topGap: '28vh',
          returnIcon: <ErrorOutlineRounded className={this.props.classes.errorIcon} />,
          buttonDisabled: true,
          boxHidden: 'none',
          topResponse: 'An error has occurred',
          boxHeight: '35vh',
          response: <>
            <Typography variant="body1">
              {'Status - ' + mongoResponse.response.status}
            </Typography>
            <Typography variant="body1">
              {mongoResponse.response.data.data || mongoResponse.message}
            </Typography>
            <br />
            <Typography variant="body1" style={{marginBottom: "8px"}}>
              {'A system administrator will be alerted to this and a solution found at the earliest convenience.'}
            </Typography>
          </>
        });
      }
    }
    else {
      // 'Respond with error saying could not be found'
      this.setState(state => ({
        ...state,
        responseError: mongoResponse
      }))
      this.setState({
        topGap: '28vh',
        returnIcon: <ErrorOutlineRounded className={this.props.classes.errorIcon} />,
        buttonDisabled: true,
        boxHidden: 'none',
        topResponse: 'An error has occurred',
        boxHeight: '35vh',
        response: <>
          <Typography variant="body1">
            {'Status - ' + mongoResponse.response.status}
          </Typography>
          <Typography variant="body1">
            {mongoResponse.response.data.data}
          </Typography>
          <br />
          <Typography variant="body1" style={{marginBottom: "8px", marginTop: "6px"}}>
            {'A system administrator will be alerted to this and a solution found at the earliest convenience.'}
          </Typography>
        </>
      });
    }
  }

  commentChange = (e) => {
    const comment = this.state.responseData ? [...this.state.responseData.request.body].find(x => x.internalName === 'comment').value : ""
    let buttonState = true;
    if(e.target.value !== comment) {
      buttonState = false
    }
    this.setState({
      comment: e.target.value,
      submitted: buttonState
    });
  }

  render() {
    const {classes} = this.props;
    if(!this.state.responseData && !this.state.responseError) {
      return <LoadingSpinner/>
    }
    return (
      <Grid container justify="center" style={{ height: '100vh' }}>
        <Grid item xs={12} sm={8} md={4} xl={3} style={{ marginTop: this.state.topGap, marginBottom: 'auto', maxWidth: '363px' }}>
          <Card className={classes.card}>
            {this.state.returnIcon}
            <Box textAlign="center" mt={1} style={{height: "30px"}}>
              <Typography variant="h5">
                {this.state.topResponse}
              </Typography>
            </Box>
            <Box textAlign="center" className={classes.spacer}>
              {this.state.response}
            </Box>
            <TextInput style={{ display: this.state.boxHidden }} placeholder='Comments' id="addComment" onChange={this.commentChange} multiline rows={9} value={this.state.comment}></TextInput>
            <Box textAlign="center" mt={3} style={{ display: this.state.boxHidden }} >
              <Button disabled={this.state.buttonDisabled} fullWidth variant='contained' onClick={() => this.updateComment()} color={this.state.submitted ? 'success' : 'info'}>{this.state.submitted ? "Thank You" : "Submit"}</Button>
            </Box>
          </Card>
          <Box textAlign="center" style={{marginTop: '21.5px'}}>
            <img src={logo} style={{ maxWidth: '100px', justify: "center" }} />
          </Box>
        </Grid>
      </Grid>
    )
  }
}

export default (withStyles)(styles)(ocsFrontEnd);
