import React, { Component } from 'react'
import {Radio as MuiRadio, FormControlLabel} from '@material-ui/core';
import {withTheme} from '@material-ui/styles';
class Radio extends Component {
  render() {
    const {theme, ...rest} = this.props;
    return (
      <MuiRadio
        icon={
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" fill={theme.palette.info.main}/>
          </svg>
        }
        checkedIcon={
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" fill={theme.palette.info.main}/>
            <circle cx="8" cy="8" r="4" fill="white"/>
          </svg>
        }
        {...rest}
      />
    )
  }
}

export default withTheme(Radio);
