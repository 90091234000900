import React, { Component } from 'react';
import { Dialog as MuiDialog, DialogContent, DialogTitle, FormControlLabel, Grid, Card, DialogActions } from '@material-ui/core';
import {withStyles, withTheme} from '@material-ui/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import momentUtils from '@date-io/moment';
import '../../css/App.css';
import { MoreVert } from '@material-ui/icons';
import defaults from './defaults.json';
import { Alerts, Button, Table, TextInput, Toolbar, Typography, SimpleDialog, Checkbox } from '../../components';
import { createMongoCustomer, getMongoCustomerData, deleteCustomer, editCustomer, getStatistics } from '../../indexBackend';
import { EditCustomerDialog } from './components';
import moment from 'moment';
import helper from '../../helpers/helper';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

const { v4: uuidv4 } = require('uuid');


const styles = theme => ({
  card: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0
  }
})

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogs: {
        deleteConfirmationDialog: defaults.deleteConfirmationDialog,
        newCustomerDialog: defaults.newCustomerDialog,
        editCustomerDialog: defaults.editCustomerDialog
      },
      filters: {
        search: "",
        license: defaults.filters.license
      },
      selected: null,
      expiryDate: moment(),
      checked: false,
      companyName: '',
      selectedCustomers: [],
      tableData: {
        labels: [],
        rows: []
      }
    };
  }

  async componentDidMount() {
    this.state.uniqueId = uuidv4()

    axios({
      url: '/api/checkLogin',
      method: 'POST',
      headers: { 'content-type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') },
    }).then((data) => {
      this.getCustomerData()
    })
      .catch(err => {
        this.props.history.push('/login');
      })
  }

  dialogStateHandler = (dialog, resetSelected) => {
    if (this.state.dialogs[dialog].open) {
      this.setState(state => ({
        ...state,
        selected: resetSelected ? null : state.selected,
        dialogs: {
          ...state.dialogs,
          [dialog]: defaults[dialog]
        }
      }))
    } else {
      this.setState(state => ({
        ...state,
        dialogs: {
          ...state.dialogs,
          [dialog]: {
            ...state.dialogs[dialog],
            open: true
          }
        }
      }))
    }
  }

  clearSelected = () => {
    this.setState(state => ({
      ...state,
      selected: null
    }))
  }

  resetNewCustomerDialog = () => {
    this.setState(state => ({
      ...state,
      companyName: "",
      industryName: "",
      keyContact: '',
      keyContactEmailAddress: '',
      logo: null,
      expiry: moment(),
      uniqueId: uuidv4(),
      trial: false,
      hostname: '',
      companyNameError: false,
      industryError: false,
      keyContactError: false,
      keyContactEmailAddressError: false,
      hostnameError: false,
      logoError: false
    }))
  }

  createTableData = async () => {
    let customers = [...this.state.customers];
    const { search, ...rest } = this.state.filters;
    const usageData = await getStatistics()
    if (search || Object.values(rest.license).includes(true)) {
      customers = customers.filter(x => {
        const now = moment().format('x');
        const expiry = x.expiry;
        return (
          (
            x.companyName.toLowerCase().match(search.toLowerCase()) ||
            x.industry.toLowerCase().match(search.toLowerCase())
          ) &&
          (
            Object.values(rest.license).includes(true) ?
            (
              (rest.license.noLicense && !x.expiry && !x.trial) || 
              (rest.license.trial && x.trial) ||
              (rest.license.expired && now > x.expiry) ||
              (rest.license.expiringSoon && now <= x.expiry && now > x.expiry - ((30 * 60 * 60 * 24) * 1000) && !x.trial) ||
              (rest.license.licensed && now < expiry && !x.trial)
            ) : true
          )
        )
      })
    }

    const tableData = {
      labels: [
        {
          label: 'Customer Name',
          sortable: true
        },
        {
          label: 'Usage',
          sortable: true
        },
        {
          label: 'Status',
          sortable: true
        },
        {
          label: 'Expiry Date',
          sortable: true
        }
      ]
    }

    const rows = customers.map(x => {
      let currentUsage
      usageData.data.data.customerTotal.forEach(usage => {
        if (x._id === usage.customer_id) {
          currentUsage = usage.usage
        }
      })
      const status = {
        text: 'No Licence',
        color: 'secondary'
      }

      if (x.expiry) {
        const now = moment().format('x');
        const expiry = moment(x.expiry).format('x');

        if (now > expiry) {
          status.text = "Expired";
          status.color = 'error';
        } else {
          if (now <= expiry && now > expiry - ((30 * 60 * 60 * 24) * 1000)) {
            status.text = "Expiring Soon";
            status.color = 'warning';
          } else {
            status.text = "Licensed";
            status.color = 'success';
          }
        }
      }

      if (x.trial && x.trial === true) {
        status.text = "Trial";
        status.color = "info";
      }

      if (x.enabled && x.enabled === false) {
        status.text = "Disabled";
        status.color = "error";
      }
      return {
        id: x._id,
        onClick: () => this.props.history.push(`/customer-administration/${x._id}`),
        cells: [
          {
            sortBy: x.companyName,
            type: 'imagetext',
            options: {
              imageUrl: x.logo,
              alt: x.companyName,
              shape: 'square',
              altType: 'text',
              text: {
                primary: x.companyName,
                secondary: x.industry
              }
            }
          },
          currentUsage ? currentUsage : 0,
          {
            sortBy: status.text,
            type: 'status',
            options: {
              text: status.text,
              color: status.color
            }
          },
          {
            sortBy: x.expiry,
            type: "text",
            options: {
              text: {
                primary: x.expiry ? moment(x.expiry).format('DD/MM/yyyy') : "",
              }
            }
          },

          {
            type: 'actions',
            options: {
              button: <MoreVert />,
              click: () => this.setSelected(x._id),
              heading: "Customer Actions",
              actions: [
                { "display": "Edit Customer", action: () => this.setSelected(x._id, true) },
                // {"display": "Disable", color: 'warning', action: () => this.toggleCustomer(x._id)},
                { "display": "Delete", color: 'error', action: () => this.dialogStateHandler('deleteConfirmationDialog') }
              ]
            }
          }
        ]
      }
    })

    tableData.rows = rows;

    this.setState(state => ({
      ...state,
      tableData
    }))
  }

  setSelected = (id, openDialog) => {
    const selected = [...this.state.customers].find(x => x._id === id);

    if (selected) {
      this.setState(state => ({
        ...state,
        selected
      }), () => {
        if (openDialog) {
          this.dialogStateHandler('editCustomerDialog');
        }
      })
    }
  }

  editCustomerHandler = (id) => {
    if (id) {
      // Single

      const selected = { ...this.state.selected };

      if (selected.expiry) {
        selected.expiry = moment(selected.expiry).format('x');
      }

      editCustomer(id, selected).then(c => {
        this.getCustomerData();
        Alerts({ type: 'success', text: 'Customer successfully updated' });
        this.dialogStateHandler('editCustomerDialog');
      }).catch(err => {
        Alerts({type: "error", text: err.response.data.data || err.message})
      })
    }
  }

  toggleCustomer = (id) => {
    if (id) {
      // Single
      const customers = [...this.state.customers];
      const customer = customers.find(x => x._id === id);
      editCustomer(id, { enabled: !customer.enabled ? false : !this.state.selected.enabled }).then(data => {

        customer.enabled = data.data.enabled;

        this.setState(state => ({
          ...state,
          customers
        }), () => {
          this.createTableData();
        })
      }).catch(err => {
        console.error(err);
        Alerts({type: 'error', text: err.response.data.data || err.message})
      })
    }
  }

  deleteCustomerHandler = async (id) => {
    const customers = [...this.state.customers];
    if (id) {
      // Single

      const index = customers.findIndex(x => x._id === id);

      await deleteCustomer(id).then(data => {
        if (index !== -1) {
          const name = customers[index].companyName
          customers.splice(index, 1);

          Alerts({ text: `${name} successfully deleted`, type: 'success' });

          this.setState(state => ({
            ...state,
            customers
          }), () => {
            this.createTableData();
            this.dialogStateHandler('deleteConfirmationDialog', true);
          })
        }
      });

    } else {
      // Multiple
      const selectedCustomers = [...this.state.selectedCustomers];

      if (selectedCustomers.length === 0) {
        return
      }

      const results = await Promise.allSettled(selectedCustomers.map(async id => {

        await deleteCustomer(id);
        selectedCustomers.splice(selectedCustomers.indexOf(id), 1);
      }));

      const failedResults = results.filter(x => x.status === "rejected");
      const successfulResults = results.filter(x => x.status === "fulfilled");

      if (successfulResults.length > 0)
        if (failedResults.length > 0)
          Alerts({ text: `Successfully deleted ${successfulResults.length} customer${successfulResults.length > 1 ? "s" : ""}.<br/>Failed to delete ${failedResults.length} customer${failedResults.length > 1 ? "s" : ""}.`, type: "warning" });
        else
          Alerts({ text: `Successfully deleted ${successfulResults.length} customer${successfulResults.length > 1 ? "s" : ""}.`, type: "success" });
      else
        Alerts({ text: `Failed to delete ${failedResults.length} customer${failedResults.length > 1 ? "s" : ""}.`, type: "error" });

      this.setState(state => ({
        ...state,
        customers,
        selectedCustomers: []
      }), () => {
        this.getCustomerData();
        this.dialogStateHandler('deleteConfirmationDialog', true);
      })
    }
  }

  async getCustomerData() {
    let customerData = await getMongoCustomerData();

    this.setState(state => ({
      ...state,
      customers: customerData.data.data
    }), () => {
      this.createTableData()
    })
  }

  async addNewAccount() {
    let missingFields = false
    let newAccountDetails = {
      companyName: this.state.companyName,
      industry: this.state.industryName,
      keyContact: {
        name: this.state.keyContact,
        email: this.state.keyContactEmailAddress
      },
      logo: this.state.logo,
      expiry: moment(this.state.expiryDate).format('x'),
      id: this.state.uniqueId,
      trial: this.state.trial,
      hostname: this.state.hostname,
      enabled: true
    }
    if (this.state.companyName === '' || this.state.companyName === undefined) {
      this.setState({ companyNameError: true })
      missingFields = true
    }
    if (this.state.industryName === '' || this.state.industryName === undefined) {
      this.setState({ industryError: true })
      missingFields = true
    }
    if (this.state.keyContact === '' || this.state.keyContact === undefined) {
      this.setState({ keyContactError: true })
      missingFields = true
    }
    if (this.state.keyContactEmailAddress === '' || this.state.keyContactEmailAddress === undefined) {
      this.setState({ keyContactEmailAddressError: true })
      missingFields = true
    }
    if (this.state.hostname === '' || this.state.hostname === undefined) {
      this.setState({ hostnameError: true })
      missingFields = true
    }
    if (missingFields !== true) {
      createMongoCustomer(newAccountDetails).then(data => {
        this.dialogStateHandler('newCustomerDialog', true)
        this.getCustomerData()
        Alerts({ text: `Customer successfully created`, type: 'success' });
      }).catch(err => {
        Alerts({ type: 'error', text: err.response.data.data || err.message});
      })
    }
  }

  selectionHandler = (customer) => {
    let selectedCustomers = [...this.state.selectedCustomers];
    if (customer) {
      const customerIndex = selectedCustomers.indexOf(customer);
      customerIndex > -1
        ? selectedCustomers.splice(customerIndex, 1)
        : selectedCustomers.push(customer);
    } else {
      selectedCustomers = selectedCustomers.length !== this.state.tableData.rows.length
        ? this.state.tableData.rows.map(x => x.id)
        : [];
    }

    this.setState(state => ({
      ...state,
      selectedCustomers
    }), () => {
      this.createTableData();
    });
  }

  async toggleModal(toggle = true) {
    if (toggle === true) {
      this.setState({
        open: !this.state.open,
        companyName: '',
        industryName: '',
        keyContact: '',
        keyContactEmailAddress: '',
        hostname: '',
        uniqueId: uuidv4(),
        companyNameError: false,
        industryError: false,
        keyContactError: false,
        keyContactEmailAddressError: false,
        hostnameError: false,
        logoError: false
      })
    }
    else {
      this.setState({
        companyName: '',
        industryName: '',
        keyContact: '',
        keyContactEmailAddress: '',
        hostname: '',
        uniqueId: uuidv4(),
        companyNameError: false,
        industryError: false,
        keyContactError: false,
        keyContactEmailAddressError: false,
        hostnameError: false
      })
    }
  }

  filterFieldHandler = (event) => {
    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [event.target.name]: event.target.value
      }
    }), () => {
      this.createTableData();
    })
  }

  additionalFiltersHandler = (event, type) => {
    const selectors = event.target.name.split('-');
    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [selectors[0]]: {
          ...state.filters[selectors[0]],
          [type === 'radio' ? 'selected' : selectors[1]]: type === 'radio' ? event.target.value : event.target.checked
        }
      }
    }), () => {
      this.createTableData();
    })
  }

  handleUpload = async (file) => {
    const base64 = await helper.getBase64(file);

    this.setState(state => ({
      ...state,
      logo: base64
    }))
  }

  dialogFieldHandler = async (event, val) => {
    let file = null;
    const identifiers = event ? event.target.name.split('-') : ['expiry'];

    if(identifiers[0] === 'trial' && val === true) {
      this.setState(state => ({
        ...state,
        selected: {
          ...state.selected,
          expiry: moment().add(30, 'd')
        }
      }))
    }

    if (event?.target?.files) {
      file = await helper.getBase64(event.target.files[0]);
    }

    this.setState(state => ({
      ...state,
      selected: {
        ...state.selected,
        [identifiers[0]]: identifiers.length === 1 ? file || event?.target.value || event?.target.checked || val : {
          ...state.selected[identifiers[0]],
          [identifiers[1]]: event?.target.value || event?.target.checked || val
        }
      }
    }))
  }

  // saveCustomer = (id) => {
  //   editCustomer(id, this.state.selected).then(data => {
  //     const customers = [...this.state.customers];
  //     let selected = customers.find(x => x._id === id);
  //     const modified = {...this.state.modified}
  //     if(modified.expiry && modified.expiry !== 0) {
  //       modified.expiry = moment(modified.expiry).format('x');
  //     }

  //     selected = Object.assign(selected, this.state.selected);

  //     if(selected) {
  //       this.setState(state => ({
  //         ...state,
  //         customers
  //       }), () => {
  //         this.getCustomerData();
  //       })
  //     }
  //   })
  // }

  render() {
    const { classes, theme } = this.props;
    return (
      <>
        <SimpleDialog
          open={this.state.dialogs.deleteConfirmationDialog.open}
          close={() => this.dialogStateHandler('deleteConfirmationDialog', true)}
          heading="Are you sure?"
          content={
            <>
              {
                !this.state.selected && this.state.selectedCustomers.length ?
                  <>
                    <Typography variant='body1'>
                      {`You're deleting ${this.state.selectedCustomers.length} records.`}
                    </Typography>
                    <br />
                  </>
                  : null
              }
              <Typography variant='body1'>
                Deleting a Customer will revoke their access from OneClick Survey. All their data will remain for statistical purposes.
              </Typography>
            </>
          }
          submit={() => this.deleteCustomerHandler(this.state.selected?._id)}
          submitText="Delete"
        />
        <EditCustomerDialog
          open={this.state.dialogs.editCustomerDialog.open}
          close={() => this.dialogStateHandler('editCustomerDialog', true)}
          save={this.editCustomerHandler}
          customer={this.state.selected}
          fieldHandler={this.dialogFieldHandler}
          customerFields={defaults.editCustomerDialog.fields}
        />
        <Grid container>
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Toolbar
                filterValue={this.state.filters.search}
                filterTranslation={{
                  "license": {
                    "category": "License",
                    "licensed": "Licensed",
                    "expiringSoon": "Expiring Soon",
                    "expired": "Expired",
                    "trial": "Trial",
                    "noLicense": "No License"
                  }
                }}
                filterFieldHandler={this.filterFieldHandler}
                additionalFilters={this.state.filters}
                additionalFiltersHandler={this.additionalFiltersHandler}
                recordNumber={this.state.customers?.length || 0}
                selectedNumber={this.state.selectedCustomers?.length || 0}
                showingNumber={this.state.tableData?.rows?.length || 0}
                actions={[
                  {
                    onClick: () => this.dialogStateHandler('newCustomerDialog'),
                    color: theme.palette.info.main,
                    type: "add",
                    tooltip: "Add Customer"
                  },
                  {
                    onClick: () => {this.clearSelected(); this.dialogStateHandler('deleteConfirmationDialog', true)},
                    color: theme.palette.error.main,
                    type: "delete",
                    disabled: !this.state.selectedCustomers.length,
                    tooltip: "Delete Customer"
                  }
                ]}
              />
              <Table
                dense
                tableData={this.state.tableData}
                selectedArr={this.state.selectedCustomers}
                selectionHandler={this.selectionHandler}
                recordsPerPage={10}
              />
            </Card>
          </Grid>
        </Grid>
        <MuiDialog fullWidth maxWidth="sm" open={this.state.dialogs.newCustomerDialog.open} onClose={() => this.dialogStateHandler('newCustomerDialog', true)} onExit={this.resetNewCustomerDialog}>
          <DialogTitle>
            <Typography variant="h5">
              New Customer
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Fill in all the details below to create a new customer.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  placeholder="Company Name"
                  value={this.state.companyName}
                  onChange={(e) => this.setState({ companyNameError: false })}
                  error={this.state.companyNameError}
                  color={'secondary'}
                  InputLabelProps={{
                    color: 'secondary'
                  }}
                  label={<label>Company Name<font color="red"> *</font></label>}
                  onChange={(e) => this.setState({ companyName: e.target.value, companyNameError: false })}
                  id="companyName"
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput placeholder="Industry" value={this.state.industryName} error={this.state.industryError} color={'secondary'} InputLabelProps={{
                  color: 'secondary',
                  Typography: 'primary'
                }} label={<label>Industry<font color="red"> *</font></label>} onChange={(e) => this.setState({ industryName: e.target.value, industryError: false })} id="industryName"></TextInput>
              </Grid>
              <Grid item xs={12}>
                <TextInput placeholder="Key Contact" value={this.state.keyContact} error={this.state.keyContactError} color={'secondary'} InputLabelProps={{
                  color: 'secondary'
                }} label={<label>Key Contact<font color="red"> *</font></label>} onChange={(e) => this.setState({ keyContact: e.target.value, keyContactError: false })} id="keyContactName"></TextInput>
              </Grid>
              <Grid item xs={12}>
                <TextInput placeholder="Key Contact Email Address" value={this.state.keyContactEmailAddress} onChange={(e) => this.setState({})} error={this.state.keyContactEmailAddressError} color={'secondary'} InputLabelProps={{
                  color: 'secondary'
                }} label={<label>Key Contact Email Address<font color="red"> *</font></label>} onChange={(e) => this.setState({ keyContactEmailAddress: e.target.value, keyContactEmailAddressError: false })} id="keyContactEmailAddress"></TextInput>
              </Grid>
              <Grid item xs={12}>
                <TextInput placeholder="Hostname" value={this.state.hostname} error={this.state.hostnameError} color={'secondary'} InputLabelProps={{
                  color: 'secondary'
                }} label={<label>Hostname<font color="red"> *</font></label>} onChange={(e) => this.setState({ hostname: e.target.value, hostnameError: false })} id="hostname"></TextInput>
              </Grid>
              <Grid item xs={12}>
                <TextInput type="file" placeholder="Upload Logo" error={this.state.uploadLogoError} color={'secondary'} InputLabelProps={{
                  color: 'secondary',
                  shrink: true
                }} label={<label>Upload Logo</label>} onChange={async (e) => await this.handleUpload(e.target.files[0])} id="uploadLogo"></TextInput>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.trial}
                      onChange={(e) => {
                        this.setState(state => ({
                          ...state,
                          trial: e.target.checked,
                          expiryDate: e.target.checked ? moment().add(30, 'days') : state.expiryDate
                        }))
                      }}
                    />
                  }
                  label={<Typography variant='body2' bold>Trial (provides 30-days access)</Typography>}
                />
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={momentUtils}>
                  <DatePicker
                    label="Expiry Date"
                    format="DD/MM/yyyy"
                    value={moment(this.state.expiryDate)}
                    onChange={val => this.setState({ expiryDate: moment(val) })}
                    invalidDateMessage={!this.state.expiryDate ? "No Expiry Date Set" : 'Invalid Date Format'}
                    TextFieldComponent={(props) => <TextInput
                      {...props}
                      InputLabelProps={{
                        color: 'secondary'
                      }}
                    />
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <TextInput placeholder="UID" error={this.state.uidError} InputLabelProps={{
                }} label={<label>UID<font color="red"> *</font></label>} value={this.state.uniqueId} disabled id="uniqueId"></TextInput>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button fullWidth onClick={() => this.dialogStateHandler('newCustomerDialog', true)}>
              Cancel
            </Button>
            <Button fullWidth variant="contained" color='info' onClick={() => this.addNewAccount()}>
              Save
            </Button>
          </DialogActions>
        </MuiDialog>
      </>
    )
  }
}

export default withRouter(withTheme(withStyles(styles)(Customers)));