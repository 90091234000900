import { TableCell } from '@material-ui/core';
import React, { Component } from 'react';
import { Checkbox } from '../../../';

class checkbox extends Component {
  render() {
    const {onCheck, checked} = this.props;
    return (
      <TableCell onClick={(e) => e.stopPropagation()} padding='checkbox'>
        <Checkbox 
          name='selectAll'
          onChange={onCheck}
          checked={checked}
        />
      </TableCell>
    );
  }
}

export default checkbox;