import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {Link} from 'react-router-dom';
import {Typography, Alerts, SimpleDialog, LoadingSpinner} from '../../components';
import {InfoCard, Content, LicenseDialog} from './components';
import {deleteCustomer, getMongoCustomerData, editCustomer} from '../../indexBackend';
import defaults from './defaults.json';
import moment from 'moment';
import {EditCustomerDialog, editCustomerDialog} from '../customers/components'
import helper from '../../helpers/helper';
import {withRouter} from 'react-router-dom';
import axios from 'axios';

const styles = theme => ({
  link: {
    paddingBottom: theme.spacing(1)
  }
})
class CustomerInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: null,
      customer: null,
      modifiedCustomer: null,
      dialogs: {
        deleteConfirmationDialog: defaults.deleteConfirmationDialog,
        editCustomerDialog: defaults.editCustomerDialog,
        licenseDialog: defaults.licenseDialog
      }
    }
  }
  
  componentDidMount() {
    axios({
      url: '/api/checkLogin',
      method: 'POST',
      headers: { 'content-type': 'application/json', 'Authorization': 'Bearer '+ localStorage.getItem('token') },
    }).then((data) => {
      this.getCustomers();
    })
    .catch(err => {
      this.props.history.push('/login');
    })
  }
  
  getCustomers = async () => {
    const customers = await getMongoCustomerData();
    
    const customer = customers.data.data.find(x => x._id === window.location.pathname.split('/')[2])

    this.setState(state => ({
      ...state,
      customers: customers.data.data,
      modifiedCustomer: customer,
      customer: customer,
      dialogs: {
        ...state.dialogs,
        licenseDialog: {
          ...state.dialogs.licenseDialog,
          fields: {
            ...state.dialogs.licenseDialog.fields,
            expiryDate: customer.expiry !== 0 ? customer.expiry : moment()
          }
        }
      }
    }))
  }

  editCustomerHandler = (id, payload, dialog) => {
    if (!id)
      id = window.location.pathname.split('/')[2];

    const pl = payload ? {...payload} : {...this.state.modifiedCustomer};

    if(pl.expiry) {
      pl.expiry = moment(pl.expiry).format('x');
    }

    editCustomer(id, pl).then(data => {
      this.getCustomers();
      Alerts({type: 'success', text: 'Customer successfully updated'})
      this.dialogStateHandler('editCustomerDialog')
    }).catch(err => {
      Alerts({type: "error", text: err.response.data.data || err.message})
    });
  }

  licenceDialogHandler = (name, val) => {
    if(name === 'extend') {
      const newDate = this.state.customer.expiry !== 0 ? moment(this.state.customer.expiry).add(1, 'y') : moment().add(1, 'y');

      this.setState(state => ({
        ...state,
        dialogs: {
          ...state.dialogs,
          licenseDialog: {
            ...state.dialogs.licenseDialog,
            fields: {
              ...state.dialogs.licenseDialog.fields,
              [name]: val ,
              expiryDate: newDate
            }
          }
        }
      }))
      return
    }

    this.setState(state => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        licenseDialog: {
          ...state.dialogs.licenseDialog,
          fields: {
            ...state.dialogs.licenseDialog.fields,
            [name]: val 
          }
        }
      }
    }))
  }

  resetLicenseDialog = () => {
    this.setState(state => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        licenseDialog: {
          ...state.dialogs.licenseDialog,
          fields: {
            ...state.dialogs.licenseDialog.fields,
            extend: false,
            expiryDate: moment()
          }
        }
      }
    }))
  }

  deleteCustomerHandler = async (id) => {
    await deleteCustomer(id).then(data => {
      Alerts({type: 'success', text: 'Customer successfully deleted'})
      this.props.history.push('/customer-administration')
    });
  }

  dialogStateHandler = (dialog) => {
    this.setState(state => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        [dialog]: {
          ...state.dialogs[dialog],
          open: !state.dialogs[dialog].open
        }
      }
    }))
  }

  editFieldHandler = async (event, val) => {
    let file = null;
    const identifiers = event ? event.target.name.split('-') : ['expiry'] ;

    if(identifiers[0] === 'trial' && val === true) {
      this.setState(state => ({
        ...state,
        modifiedCustomer: {
          ...state.modifiedCustomer,
          expiry: moment().add(30, 'd')
        }
      }))
    }

    if(event?.target?.files) {
      file = await helper.getBase64(event.target.files[0]);
    }

    this.setState(state => ({
      ...state,
      modifiedCustomer: {
        ...state.modifiedCustomer,
        [identifiers[0]]: identifiers.length === 1 ? file || event?.target.value || event?.target.checked || val : {
          ...state.modifiedCustomer[identifiers[0]],
          [identifiers[1]]:  event?.target.value || event?.target.checked || val
        }
      }
    }))
  }


  render() {
    const { classes } = this.props;

    if(!this.state.customers) {
      return <LoadingSpinner/>
    }

    return (
      <>
         <SimpleDialog
          open={this.state.dialogs.deleteConfirmationDialog.open}
          close={() => this.dialogStateHandler('deleteConfirmationDialog', true)}
          heading="Are you sure?"
          content={
            <>
              <Typography variant='body1'>
                Deleting a Customer will revoke their access from OneClick Survey. All their data will remain for statistical purposes.
              </Typography>
            </>
          }
          submit={() => this.deleteCustomerHandler(window.location.pathname.split('/')[2])}
          submitText="Delete"
        />
        <LicenseDialog
          fieldValues={this.state.dialogs.licenseDialog.fields}
          fieldHandler={this.licenceDialogHandler}
          open={this.state.dialogs.licenseDialog.open}
          close={() => this.dialogStateHandler('licenseDialog')}
          save={this.editCustomerHandler}
          exit={this.resetLicenseDialog}
        />
        <EditCustomerDialog
          open={this.state.dialogs.editCustomerDialog.open}
          close={() => this.dialogStateHandler('editCustomerDialog')}
          save={this.editCustomerHandler}
          fieldHandler={this.editFieldHandler}
          customer={this.state.modifiedCustomer}
        />
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.link}>
              <Link to='/customer-administration'>
                {"< Back to Customer Administration"}
              </Link>
            </Typography>
          </Grid>
          <InfoCard
            customer={this.state.customer}
            licenseToggle={() => this.dialogStateHandler('licenseDialog')}
            editToggle={() => this.dialogStateHandler('editCustomerDialog')}
            deleteToggle={() => this.dialogStateHandler('deleteConfirmationDialog')}
          />
          <Content/>
        </Grid>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(CustomerInformation));