import React, { Component } from 'react';
import { Card, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Typography, LoadingSpinner, Button } from '../../../../components';
import {getMongoCustomerData} from '../../../../indexBackend';
import moment from 'moment';
import {ApartmentRounded} from '@material-ui/icons';

const styles = theme => ({
  card: {
    padding: '0'
  },
  image: {
    height: "113px",
    width: "113px",
    borderRadius: '24px',
    margin: '15px 21.5px',
    overflow: 'hidden'
  },
  company: {
    display: 'flex',
    flexDirection
    : 'row'
  },
  companyDetails: {
    margin: '17px 24px',
    display: "flex",
    flexDirection: "column"
  },
  customerInfo: {
    margin: '24px 24px',
  },
  gridItem: {
    height: "47px",
    display: "flex",
    alignItems: 'center'
  },
  value: {
    color: theme.palette.text.secondary
  },
  grid: {
    '& > :not(:last-child)' :{
      paddingBottom: '5px'
    }
  },
  spacer: {
    flexGrow: 1
  },
  actions: {
    '& > :not(:last-child)' :{
      marginRight: theme.spacing(2)
    }
  },
  img: {
    height: 'inherit',
    width: 'inherit'
  },
  subheader: {
    color: theme.palette.neutral[1]
  },
  altImgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: "100%",
    backgroundColor: theme.palette.neutral[2],
    '& svg': {
      marginTop: 'auto',
      marginBottom: 'auto',
      fontSize: "3.05rem",
      color: theme.palette.text.hint
    }
  }
})

class InfoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: null
    }
  }
  

  componentDidMount() {
    this.getCustomers();
  }
  
  getCustomers = async () => {
    const customers = await getMongoCustomerData();
    const id = window.location.pathname.split('/')[2];

    const selected = customers.data.data.find(x => {
      return x._id === id;
    })

    if(selected) {
      this.setState(state => ({
        ...state,
        customer: selected
      }))
    }
  }
  
  render() {
    if(!this.props.customer) {
      return <LoadingSpinner/>
    }

    const {classes, licenseToggle, deleteToggle, editToggle, customer} = this.props;

    return (
      <Grid item xs={12}>
        <Card className={classes.card}>
          <Grid container direction='row' justify='space-between'>
            <Grid container item direction='row'>
              <Grid item xs={8} className={classes.company}>
                <div className={classes.image}>
                  {
                    customer.logo ? 
                    <img className={classes.img} src={customer?.logo} alt={customer?.companyName} />
                    : <div className={classes.altImgWrapper}>
                        <ApartmentRounded fontSize='large'/>
                      </div>
                  }
                </div>
                <div className={classes.companyDetails}>
                  <Typography variant="h5">
                    {customer?.companyName}
                  </Typography>
                  <Typography variant="smallCapsHeavy" className={classes.subheader}>
                    {customer?.industry}
                  </Typography>
                  <div className={classes.spacer}></div>
                  <div className={classes.actions}>
                    <Button className={classes.button} disableElevation color='success' variant='contained' size='small' onClick={licenseToggle}>
                      Renew License
                    </Button>
                    <Button className={classes.button} disableElevation color='info' variant='contained' size='small' onClick={editToggle}>
                      Edit Customer
                    </Button>
                    <Button className={classes.button} color='error' size='small' onClick={deleteToggle}>
                      Delete
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.customerInfo}>
                  <Grid container className={classes.grid}>
                    <Grid item xs={6}>
                      <Typography variant="buttonSmall" bold>
                        UID
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.value} variant='buttonSmall'>
                        {customer._id}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="buttonSmall" bold>
                        Key Contact
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.value} variant='buttonSmall'>
                        {customer.keyContact.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="buttonSmall" bold>
                        Key Contact Email
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.value} variant='buttonSmall'>
                        {customer.keyContact.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{paddingBottom: 0}}>
                      <Typography variant="buttonSmall" bold>
                        License Expiry
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.value} variant='buttonSmall'>
                        {customer.expiry ? moment(customer?.expiry).format('DD/MM/yyyy') : "Not Licensed"}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(InfoCard);