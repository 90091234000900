import React from 'react';
import {Sidebar, Topbar} from './components';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/styles';

const styles = theme => ({
  content: {
    height: '100%',
    padding: theme.spacing(1)
  },
  wrapper: {
    padding: '55px 0 0 64px'
  }
});

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.languagePack[window.location.pathname.split('/')[1]].pageTitle
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.page !== this.props.languagePack[window.location.pathname.split('/')[1]].pageTitle) {
      this.setState(state => ({
        ...state,
        page: this.props.languagePack[window.location.pathname.split('/')[1]].pageTitle
      }))
    }
  }

  render() {
    const {classes, children, title } = this.props;

  
    return(
      <React.Fragment>
        <div className={classes.wrapper}>
          <Topbar title={this.state.page} dialogHandler={this.dialogHandler}/>
          <Sidebar languageDialogOpenHandler={this.languageDialogOpenHandler}/>
          <main className={classes.content}>
            {children}
          </main>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({languagePack, languages, enabled}) => {
  const page = window.location.pathname.split('/')[1]
  return {
    languagePack: languagePack,
    title: languagePack[page].pageTitle
  }
}


export default connect(mapStateToProps)(withStyles(styles)(Main));