import React, {useState} from 'react';
import propTypes from 'prop-types';
import {Menu, MenuItem, TableCell, IconButton, ListSubheader, Backdrop} from '@material-ui/core';
import {withTheme, withStyles} from '@material-ui/styles';
import {Typography} from '../../../../components';

const styles = theme => ({
  popover: {
    maxWidth: "219px",
    boxSizing: 'border-box',
    width: "219px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: 'none',
    border: "1px solid rgba(218, 222, 227, 1)",
    borderRadius: "4px"
  },
  subheader: {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  item: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    lineHeight: "20px",
    // height: "20px",
    // '&:last-child' :{
    //   marginBottom: '0px'
    // }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
})

const Actions = props => {
  const {classes, theme, actions, button, click, heading} = props;

  const [state, setState] = useState({
    open: false,
    anchorEl: null
  })
  const menuItems = actions.map((x, index) =>{
    const {disabled, color = "text", icon, display}= x;
    const { colorVariant = color === "text" ? "primary" : color === "grey" ? "700" : "main" } = x;

    return (
      <MenuItem 
        disableGutters
        key={index}
        onClick={(e) => {
          x.action() 
          MenuHandler(e)
        }}
        className={classes.item}
        disabled={disabled}
        style={{color: theme.palette[color][colorVariant]}}
      >
        {icon}
        <Typography variant='body2' bold>
          {display}
        </Typography>
      </MenuItem>
    )
  })

  const MenuHandler = (event) => {
    event.stopPropagation();
    if(state.open) {
      setState(state => ({
        ...state,
        open: false
      }));
    } else {
      if(click) {
        click()
      }
      setState(state => ({
        ...state,
        open: true,
        anchorEl: state.anchorEl || event.currentTarget
      }));
    }
  }

  return (
    <>
      <TableCell align='right'>
        <Backdrop open={state.open} className={classes.backdrop}>
        </Backdrop>
        <IconButton size="small" onClick={MenuHandler}>{button}</IconButton>
        <Menu 
          anchorEl={state.anchorEl} 
          open={state.open} 
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            className: classes.popover
          }}
          onClose={MenuHandler} 
          onClick={(event) => event.stopPropagation()}
        >
          {
            heading ? <ListSubheader component='div' disableGutters className={classes.item}>
              <Typography variant='caption' className={[classes.subheader].join(' ')} style={{lineHeight: '16px'}}>
                {heading}
              </Typography>
            </ListSubheader> : null
          }
          {menuItems}
        </Menu>
      </TableCell>
    </>
  )
}

Actions.propTypes = {
  button: propTypes.element,
  actions: propTypes.array.isRequired
} 

export default withStyles(styles)(withTheme(Actions));