import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export default function (AuthComponent) {
  class AuthCheck extends Component {
    render() {
      if (!localStorage.getItem('token')) {
        return <Redirect to='/login' />
      }
      return <AuthComponent />
    }
  }

  return AuthCheck;
}