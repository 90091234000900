const theme = {
  typography: {
    fontFamily: [
      'Source Sans Pro',
      'sans serif'
    ].join(','),
    body1: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px"
    },
    body2: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "20px"
    },
    h4: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "23px",
      lineHeight: "36px"
    },
    h5: {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "23px",
      lineHeight: "24px"
    },
    caption: {
      fontStyle: "normal",
      fontSize: "11px",
      lineHeight: "16px"
    },
    cell: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px"
    },
    tableHeader: {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "20px"
    },
    button: {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
      textTransform: "none"
    },
    buttonSmall: {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "20px",
      textTransform: "none"
    },
    buttonLarge: {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "28px",
      textTransform: "none"
    },
    bold: {
      fontWeight: 600
    },
    smallCapsHeavy: {
      fontFamily: "Source Sans Pro",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.05em",
      textTransform: "uppercase"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        color: "rgba(79,79,79,1)",
        backgroundColor: "rgba(255, 255, 255, 1)",
        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15)"
      },
      arrow: {
        color: "rgba(255, 255, 255, 1)"
      }
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none'
      }
    },
    MuiCard: {
      root: {
        padding: "30px",
        height: "100%",
        boxSizing: "border-box",
        background: '#fff',
        borderRadius: "6px",
        border: "1px solid #EBEEF2",
        "&.MuiPaper-outlined": {
          border: "1px solid #DADEE3"
        }
        // boxShadow: "0px 0px 30px 0px rgb(82 63 105 / 5%)"
      }
    },
    MuiCardHeader: {
      root: {
        padding: 0
      }
    },
    MuiCardContent: {
      root: {
        padding: 0,
        '&:last-child': {
          paddingBottom: 0
        }
      },
    },
    MuiChip: {
      root: {
        height: "28px",
        lineHeight: "28px"
      }
    },
    MuiDialogTitle: {
      root: {
        padding: "24px 24px 16px 24px"
      }
    },
    MuiDialog: {
      root: {
        whiteSpace: "break-spaces",
      },
      paper: {
        borderRadius: 16
      }
    },
    MuiDialogContent: {
      root: {
        padding: "8px 24px"
      }
    },
    MuiDialogActions: {
      root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "16px 24px 24px",
      },
      spacing: {
        "& > :not(:first-child)": {
          marginLeft:"16px"
        }
      }
    }, 
    MuiTab: {
      root: {
        marginRight: "16px",
        minWidth: 0,
        '@media (min-width: 0px)': {
          minWidth: 0
        }
      },
      textColorInherit: {
        opacity: 1
      }
    }, 
    MuiTabs: {
      root: {
        height: "43px",
      },
      indicator: {
        color: '#2B4BF2',
        backgroundColor: '#2B4BF2',
        height: "3px",
        borderRadius: '2px'
      }
    },
    MuiTableRow: {
      root: {
        borderBottom: '2px solid #F4F6F9'
      }
    },
    MuiTableRow: {
      root: {
        borderBottom: '2px solid #F4F6F9'
      }
    },
    MuiTableCell: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: "64px",
        maxHeight: "64px",
        height: "64px",
        borderBottom: 'none'
      },
      head: {
        height: '40px'
      },
      sizeSmall: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: "48px",
        maxHeight: "48px",
        height: "48px",
      },
    },
    MuiTableHead: {
      root: {
        height: '48px',
        borderBottom: '1px solid #F4F6F9',
        "& > .MuiTableRow-head": {
          borderBottom: 'none'
        }
      },
    },
    MuiTableSortLabel: {
      root: {
        '&:hover': {
          color: '#2B4BF2',
        },
        '&.MuiTableSortLabel-active': {
          color: '#2B4BF2 !important',
        },
      },
      active: {
        color: '#2B4BF2',
      },
    },
    MuiTextField: {
      root: {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'transparent',
          },
          backgroundColor: "rgba(244,246,249,1)",
          borderRadius: "8px",
          border: "none"
        },
        "& .MuiInputBase-root:not(.MuiInputBase-multiline)": {
          height: "48px"
        },
        "& .MuiInputBase-root.MuiInputBase-multiline": {
          padding: '14.5px 14px'
        },
        "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
          transform: "translate(14px, 16px) scale(1)"
        },
        "& .MuiInputBase-marginDense:not(.MuiInputBase-multiline)": {
          height: "36px",
        },
        "& .MuiInputLabel-marginDense:not(.MuiInputLabel-shrink)": {
          transform: "translate(14px, 11px) scale(1)"
        },
        "& .MuiInputLabel-root.MuiInputLabel-marginDense, .MuiInputBase-marginDense, .MuiInputAdornment-marginDense": {
          fontSize: "13px"
        },
        "& .MuiInputBase-root:is(.MuiAutocomplete-inputRoot)": {
          height: "auto",
          padding: '5px 9px'
        }
      },
    },
    MuiButton: {
      root: {
        padding: "12px 16px"
      },
      outlinedSizeLarge: {
        padding: "12px 16px"
      },
      outlinedSizeSmall: {
        padding: "8px 12px"
      },
      outlined: {
        padding: "10px 14px"
      },
      containedSizeSmall: {
        padding: "10px 14px"
      },
      containedSizeLarge: {
        padding: "14px 18px"
      },
      textSizeSmall: {
        padding: "10px 14px"
      },
      textSizeLarge: {
        padding: "14px 18px"
      },
    }
  },
  elevations: {
    tight: [
      "0px 1px 3px rgba(0, 0, 0, 0.3)",
      "0px 1.25px 4px rgba(0, 0, 0, 0.25)",
      "0px 2px 6px rgba(0, 0, 0, 0.22)",
      "0px 2px 7px rgba(0, 0, 0, 0.24)",
      "0px 3px 12px rgba(0, 0, 0, 0.2)",
      "0px 7px 18px rgba(0, 0, 0, 0.17)",
      "0px 12px 28px rgba(0, 0, 0, 0.2)",
      "0px 13px 36px rgba(0, 0, 0, 0.22)",
    ],
    fluffy: [
      "0px 5px 15px rgba(0, 0, 0, 0.15)",
      "0px 6.25px 20px rgba(0, 0, 0, 0.125)",
      "0px 10px 30px rgba(0, 0, 0, 0.11)",
      "0px 10px 35px rgba(0, 0, 0, 0.12)",
      "0px 15px 60px rgba(0, 0, 0, 0.12)",
      "0px 17.5px 70px rgba(0, 0, 0, 0.12)",
      "0px 35px 90px rgba(0, 0, 0, 0.135)",
    ]
  },
  palette: {
    primary: {
      main: '#824FFF',
      dark:'#5B37B2',
      light:'#E5DAFF',
    },
    secondary: {
      main: '#3699FF',
      dark:'#256BB2',
      light:'#D0E7FF',
      contrastText: "#ffffff"
    },
    success: {
      main: '#19AB4F',
      dark:'#3B873E',
      light:'#EDF9F0'
    },
    warning: {
      main: '#FF9800',
      dark:'#C77700',
      light:'#FFF4EC',
      contrastText: "#ffffff"
    },
    error: {
      main: '#F44336',
      dark: '#E31B0C',
      light: '#FEEFEF'
    },
    info: {
      main: '#2B4BF2',
      dark: '#0B79D0',
      light: '#EEF2FA'
    },
    grey: {
      400: '#BDBDBD'
    },
    additionals: {
      border: '#DADEE3'
    },
    action: {
      selected: 'rgba(54, 153, 255, 0.1)'
    },
    neutral: [
      '#545D69',
      '#394452',
      '#F4F6F9'
    ],
    text: {
      hint: "rgba(0, 0, 0, 0.18)"
    }
  }
}

export default theme;