import React, {useState} from 'react';
import propTypes from 'prop-types';
import {Avatar, TableCell} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import {Chip, Typography} from '../../../';

const styles = theme => ({
  pill: {
    marginRight: theme.spacing(1),
    "&:last-of-type": {
      marginRight: "0px"
    }
  }
})

const Chips = props => {
  const {classes, chips} = props;

  return (
    <TableCell>
      {
        chips ? chips.map((x, i) => {
          return <Chip className={classes.pill} size="small" label={<Typography variant="body2" bold>{x.label}</Typography>} bgColor={x.bgColor} color={x.color} variant={x.variant} key={i}/>
        }) : null
      }
    </TableCell>
  )
}

Chips.propTypes = {
  chips: propTypes.array.isRequired
}

export default withStyles(styles)(Chips);