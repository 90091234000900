import React, { Component } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Grid, FormControlLabel} from '@material-ui/core';
import moment from 'moment';
import momentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import {Typography, Button, TextInput, Checkbox} from '../../../../components';

class LicenseDialog extends Component {
  render() {
    const {open, close, save, fieldValues, fieldHandler, exit} = this.props;

    return (
      <Dialog open={open} fullWidth maxWidth='sm' onClose={close} onExit={exit}>
        <DialogTitle disableTypography>
          <Typography variant='h5'>
            Renew License
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">

              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={momentUtils}>
                <DatePicker
                  label="Expiry Date"
                  format="DD/MM/yyyy"
                  value={moment(fieldValues.expiryDate)}
                  onChange={(val) => fieldHandler('expiryDate', moment(val))}
                  TextFieldComponent={ (props) => <TextInput 
                    {...props}
                    InputLabelProps={{
                      shrink: true,
                      color: 'secondary'
                    }} 
                  />
                }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={<Typography variant='body2' bold>Renew for a further 12-months</Typography>}
                control={
                  <Checkbox
                    name="extend"
                    onChange={() => fieldHandler("extend", !fieldValues.extend)}
                    value={fieldValues.extend}
                  />
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={close}>
            Cancel
          </Button>
          <Button disableElevation fullWidth color="info" variant="contained" onClick={() => save(null, {expiry: fieldValues.expiryDate}, 'licenseDialog')}>
            Renew
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default LicenseDialog;