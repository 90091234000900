import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Chip as MuiChip } from '@material-ui/core';
import {withTheme, withStyles} from '@material-ui/styles';

class Chip extends Component {
  render() {
    const {classes, bgColor, color, theme, round, variant, ...rest} = this.props;

    const style = {
      backgroundColor: bgColor && !variant ? theme.palette[bgColor.color][bgColor.colorVariant ? bgColor.colorVariant : 'main'] : theme.palette.common.white || null,
      color: color ? theme.palette[color.color][color.colorVariant ? color.colorVariant : 'contrastText'] : theme.pallete[color.color].main,
      borderColor: color && variant === 'outlined' ? theme.palette[color.color][color.colorVariant ? color.colorVariant : 'contrastText'] : theme.pallete[color.color].main,
      borderRadius: round ? '24px' : '4px'
    }

    return (
      <MuiChip {...rest} style={style} variant={variant}/>
    );
  }
}

Chip.propTypes = {
  bgColor: PropTypes.shape({
    color: PropTypes.string,
    variant: PropTypes.string
  }),
  color: PropTypes.shape({
    color: PropTypes.string,
    variant: PropTypes.string
  })
}

export default withTheme(Chip);