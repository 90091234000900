import React, { Component } from 'react';
import { Grid, Card } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/styles';
import { PostAdd } from '@material-ui/icons';
import { Toolbar, Table, Alerts, LoadingSpinner, SimpleDialog, Typography } from '../../../../../../components';
import { getSurveysFromMongo, updateMongoSurvey, getMongoSurveyConfig, sendSurveyToEndpoint, deleteSurvey, getSurveySchemas, resendSurvey } from '../../../../../../indexBackend';
import { MoreVert } from '@material-ui/icons';
import moment from 'moment';
import defaults from './defaults.json';

const styles = theme => ({
  card: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0
  }
})

class CompletedSurveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedAll: false,
      surveysLoaded: false,
      surveys: null,
      allSurveys: null,
      selectedSurveys: [],
      selected: null,
      dialogs: {
        surveyDeleteConfirmation: defaults.surveyDeleteConfirmation
      },
      filters: {
        search: "",
        status: defaults.filters.status,
        comments: defaults.filters.comments
      }
    }
  }

  componentDidMount() {
    this.getSurveys();
  }

  getSurveys = async (all = false) => {
    this.setState(state => ({
      ...state,
      loadedAll: all,
      surveysLoaded: false,
    }));
    const id = window.location.pathname.split('/')[2]
    const options = {
      customerId: id,
      ...(!all && { limit: 1000 }),
    };
    const surveys = await getSurveysFromMongo(options);

    const filteredSurveys = surveys.data.data.filter(x => x.customerId === id);

    this.setState(state => ({
      ...state,
      surveys: filteredSurveys,
      surveysLoaded: true
    }), () => {
      this.getAllSurveys();
    })
  }

  getAllSurveys = async () => {
    const surveys = await getSurveySchemas();
    this.setState(state => ({
      ...state,
      allSurveys: surveys.data.data
    }), () => {
      this.createTableData();
    })
  }

  dialogStateHandler = (dialog) => {
    this.setState(state => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        [dialog]: {
          ...state.dialogs[dialog],
          open: !state.dialogs[dialog].open
        }
      }
    }))
  }

  selectionHandler = (customer) => {
    let selectedSurveys = [...this.state.selectedSurveys];
    if (customer) {
      const customerIndex = selectedSurveys.indexOf(customer);
      customerIndex > -1
        ? selectedSurveys.splice(customerIndex, 1)
        : selectedSurveys.push(customer);
    } else {
      selectedSurveys = selectedSurveys.length !== this.state.tableData.rows.length
        ? this.state.tableData.rows.map(x => x.id)
        : [];
    }

    this.setState(state => ({
      ...state,
      selectedSurveys
    }), () => {
      this.createTableData();
    });
  }

  async resendRequest(btn) {
    const surveys = [...this.state.surveys]
    const selectors = surveys.find(x => {
      return x._id === btn
    })
    let thisResponse = selectors
    let _id = thisResponse._id
    let responseUrl = thisResponse.request.originalUrl
    let comment = null
    let endpointData = await resendSurvey(comment, responseUrl, _id).then((data) => {
      Alerts({ type: 'success', text: 'Resend successful' });
      this.getSurveys()
    }).catch(error => {
      Alerts({ type: 'error', text: 'Resend not completed, ' + error.response.data.data });
    })
  }

  deleteSurveyHandler = async (id) => {
    const surveys = [...this.state.surveys];

    if (id) {
      const index = surveys.findIndex(x => x._id === id);

      deleteSurvey(id).then(data => {
        if (index !== -1) {
          surveys.splice(index, 1);

          Alerts({ text: `Survey successfully deleted`, type: 'success' });

          this.setState(state => ({
            ...state,
            surveys
          }), () => {
            this.createTableData();
            this.dialogStateHandler('surveyDeleteConfirmation', true);
          })
        }
      })
    } else {
      const selectedSurveys = [...this.state.selectedSurveys];

      if (selectedSurveys.length === 0) {
        return
      }

      const results = await Promise.allSettled(selectedSurveys.map(async id => {
        await deleteSurvey(id);
        selectedSurveys.splice(selectedSurveys.indexOf(id), 1);
      }));

      const failedResults = results.filter(x => x.status === "rejected");
      const successfulResults = results.filter(x => x.status === "fulfilled");

      if (successfulResults.length > 0)
        if (failedResults.length > 0)
          Alerts({ text: `Successfully deleted ${successfulResults.length} survey${successfulResults.length > 1 ? "s" : ""}.<br/>Failed to delete ${failedResults.length} survey${failedResults.length > 1 ? "s" : ""}.`, type: "warning" });
        else
          Alerts({ text: `Successfully deleted ${successfulResults.length} survey${successfulResults.length > 1 ? "s" : ""}.`, type: "success" });
      else
        Alerts({ text: `Failed to delete ${failedResults.length} survey${failedResults.length > 1 ? "s" : ""}.`, type: "error" });

      this.setState(state => ({
        ...state,
        surveys,
        selectedSurveys: []
      }), () => {
        this.getSurveys();
        this.dialogStateHandler('surveyDeleteConfirmation', true);
      })
    }
  }

  createTableData = () => {
    let surveys = this.state.surveys ? [...this.state.surveys] : [];
    sessionStorage.setItem('surveyTotal', surveys.length)
    const { search, ...rest } = this.state.filters;

    if (search || Object.values(rest.status).includes(true) || Object.values(rest.comments).includes(true)) {
      surveys = surveys.filter(x => {
        const survey = [...this.state.allSurveys].find(s => x.surveyId === s._id)?.name
        const comment = x.request?.body?.find(s => s.internalName === 'comment').value !== "";
        let txt = `Survey ID: ${x._id}`;

        const statusCode = x?.response?.statusCode;
        return (
          (
            survey?.toLowerCase().match(search.toLowerCase()) ||
            txt.toLowerCase().match(search.toLowerCase())
          ) &&
          (
            Object.values(rest.status).includes(true) ?
              (
                (rest.status.success && statusCode === 200) ||
                (rest.status.failed && statusCode && statusCode !== 200) ||
                (rest.status.unknown && !statusCode)
              ) : true
          ) &&
          (
            Object.values(rest.comments).includes(true) ?
              (
                (rest.comments.yes && comment) ||
                (rest.comments.no && !comment)
              ) : true
          )
        )
      })
    }

    const labels = [
      {
        label: "Survey",
        sortable: true
      },
      {
        label: "Created Date/Time",
        sortable: true
      },
      {
        label: "Status",
        sortable: true
      },
      {
        label: "Comments",
        sortable: true
      }
    ]

    const rows = surveys.map(x => {
      const status = {
        color: 'success',
        text: 'Success'
      }

      const roles = [];
      const actions = [
        { "display": "Resend", action: () => this.resendRequest(x._id) },
        { "display": "Delete", color: 'error', action: () => this.dialogStateHandler('surveyDeleteConfirmation') }
      ]
      if (x.request?.body?.find(s => s.internalName === 'comment').value !== "") {
        roles.push({
          label: 'Yes',
          variant: "outlined",
          bgColor: {
            color: 'success',
            colorVariant: 'main'
          },
          color: {
            color: 'success',
            colorVariant: 'main'
          }
        })
      } else {
        roles.push({
          label: 'No',
          variant: "outlined",
          bgColor: {
            color: 'grey',
            colorVariant: '400'
          },
          color: {
            color: 'grey',
            colorVariant: '400'
          }
        })
      }

      const statusCode = x?.response?.statusCode;
      if (statusCode && statusCode !== 200) {
        status.color = 'error'
        status.text = 'Failed'
      } else if (!statusCode) {
        status.color = 'warning'
        status.text = 'Unknown'
      }

      const survey = [...this.state.allSurveys].find(s => x.surveyId === s._id)

      return {
        id: x._id,
        cells: [
          {
            sortBy: x._id,
            type: 'text',
            options: {
              text: {
                primary: survey?.name,
                secondary: `Survey ID: ${x._id}`
              }
            }
          },
          {
            sortBy: x.timestamp,
            type: "text",
            options: {
              text: {
                primary: moment(Number(x.timestamp)).format('DD/MM/yyyy [at] HH:mm'),
              }
            }
          },
          {
            sortBy: status.text,
            type: 'status',
            options: status
          },
          {
            sortBy: roles[0].label || "",
            type: 'chips',
            options: {
              chips: roles
            }
          },
          {
            type: 'actions',
            options: {
              button: <MoreVert />,
              heading: 'Survey Actions',
              click: () => this.setSelected(x._id),
              actions: actions
            }
          }
        ]
      }
    })

    this.setState(state => ({
      ...state,
      tableData: {
        labels,
        rows
      }
    }))
  }

  setSelected = (id, openDialog) => {
    const selected = [...this.state.surveys].find(x => x._id === id);

    if (selected) {
      this.setState(state => ({
        ...state,
        selected
      }))
    }
  }

  filterFieldHandler = (event) => {
    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [event.target.name]: event.target.value
      }
    }), () => {
      this.createTableData();
    })
  }

  additionalFiltersHandler = (event) => {
    const selectors = event.target.name.split('-');

    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [selectors[0]]: {
          ...state.filters[selectors[0]],
          [selectors[1]]: event.target.checked
        }
      }
    }), () => {
      this.createTableData()
    })
  }

  clearSelected = () => {
    this.setState(state => ({
      ...state,
      selected: null
    }))
  }

  newSurveyFieldHandler = (event) => {
    this.setState(state => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        newSurveyDialog: {
          ...state.dialogs.newSurveyDialog,
          [event.target.name]: event.target.name
        }
      }
    }))
  }

  render() {
    const { classes, theme } = this.props;

    if (!this.state.tableData) {
      return <LoadingSpinner />
    }

    return (
      <>
        {!this.state.surveysLoaded && <LoadingSpinner/>}
        {/* <NewSurveyDialog
          fieldHandler={this.newSurveyFieldHandler}
          values={this.state.dialogs.newSurveyDialog.fields}
          open={this.state.dialogs.newSurveyDialog.open}
          close={() => this.dialogStateHandler('newSurveyDialog')}
        /> */}
        <SimpleDialog
          open={this.state.dialogs.surveyDeleteConfirmation.open}
          close={() => this.dialogStateHandler('surveyDeleteConfirmation')}
          heading="Are you sure?"
          content={
            <>
              {
                !this.state.selected && this.state.selectedSurveys.length ?
                  <>
                    <Typography variant='body1'>
                      {`You're deleting ${this.state.selectedSurveys.length} records.`}
                    </Typography>
                    <br />
                  </>
                  : null
              }
              <Typography variant='body1'>
                Deleting a Survey can’t be reversed.
              </Typography>
            </>
          }
          submit={() => this.deleteSurveyHandler(this.state.selected?._id)}
          submitText="Delete"
        />
        <Grid container>
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Toolbar
                filterValue={this.state.filters.search}
                filterTranslation={{
                  "status": {
                    "category": "Status",
                    "success": "Success",
                    "failed": "Failed",
                    "unknown": "Unknown"
                  },
                  "comments": {
                    "category": 'Comments',
                    "yes": "Yes",
                    "no": "No"
                  }
                }}
                filterFieldHandler={this.filterFieldHandler}
                additionalFilters={this.state.filters}
                additionalFiltersHandler={this.additionalFiltersHandler}
                actions={[
                  {
                    onClick: () => { this.clearSelected(); this.getSurveys(true) },
                    color: theme.palette.info.main,
                    disabled: (this.state.surveys?.length < 1000 || this.state.loadedAll),
                    tooltip: "Load All Surveys",
                    icon: <PostAdd fontSize="small"/>,
                  },
                  {
                    onClick: () => { this.clearSelected(); this.dialogStateHandler('surveyDeleteConfirmation') },
                    color: theme.palette.error.main,
                    disabled: !this.state.selectedSurveys.length,
                    type: "delete",
                    tooltip: "Delete Survey"
                  }
                ]}
                recordNumber={this.state.surveys?.length || 0}
                selectedNumber={this.state.selectedSurveys?.length || 0}
                showingNumber={this.state.tableData?.rows?.length || 0}
              />
              <Table
                dense
                tableData={this.state.tableData}
                selectionHandler={this.selectionHandler}
                selectedArr={this.state.selectedSurveys}
                recordsPerPage={50}
                defaultSort={1}
                defaultSortOrder="desc"
              />
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(withTheme(CompletedSurveys));
