
import React, { Component } from 'react';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { IconButton, CircularProgress } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import {TextInput} from '../';

import PropTypes from 'prop-types';

class AutoComplete extends Component {
  constructor (props) {
    super(props);
    
    this.state = {
      open: false
    }
  }

  setOpen = (open) => {
    this.setState(state => ({
      ...state,
      open
    }))
  }

  render() {
    const { size, disabled, loading, variant, label, onRefresh, onOpen, onClose, ...rest } = this.props;
    return (
      <MuiAutocomplete
        size={size || "medium"}
        loading={loading}
        disabled={disabled}
        onOpen={() => { this.setOpen(true); onOpen?.(); }}
        onClose={() => { this.setOpen(false); onClose?.(); }}
        renderInput={params => 
          <TextInput 
            {...params}
            label={label}
            variant={variant}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {
                  onRefresh && !loading && !disabled && this.state.open ? 
                    <IconButton size="small" onClick={() => onRefresh()} ><Refresh fontSize="small"/></IconButton> : 
                  loading ? 
                    <CircularProgress color="inherit" size={20}/> : 
                  null
                  }
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        }
        {...rest}
      />
    );
  }
}

AutoComplete.propTypes = {
  size: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  variant: PropTypes.string,
  label: PropTypes.string,
  onRefresh: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
}

export default AutoComplete;