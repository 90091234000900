import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import './css/App.css';
import Routes from './routes';
import theme from './theme';

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const muiTheme = createMuiTheme(theme);

    return (
      <div className="App">
        <ThemeProvider theme={muiTheme}>
          <Router history={this.props.history}>
            <Routes/>
          </Router>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
