import React, {useState} from 'react';
import propTypes from 'prop-types';
import {Avatar, TableCell} from '@material-ui/core';
import {Typography} from '../../..';
import {withStyles} from '@material-ui/styles';

const styles = theme => ({
  imageCell: {
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: "nowrap"
  },
  image: {
    marginRight: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    maxHeight: '40px',
    justifyContent: "center"
  },
  Text: {
    lineHeight: '20px',
  },
  imageSubText: {
    color: theme.palette.text.secondary,
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  avatar: {
    width: "32px",
    maxHeight: "32px",
    minHeight: "32px"
  }
})

const Text = props => {
  const {classes, imageUrl, text, shape} = props;

  const variant = shape ? shape : 'circular';

  return (
    <TableCell align='right'>
      <div className={classes.imageCell}>
        <div className={classes.text}>
          <div className={classes.textWrapper}>
            <Typography variant="cell" className={classes.Text}>
              {text.primary}
            </Typography>
          </div>
          {
            text.secondary ? <div>
              <Typography variant="cell" className={[classes.Text, classes.imageSubText].join(" ")}>
                {text.secondary}
              </Typography>
            </div> : null
          }
        </div>
      </div>
    </TableCell>
  )
}

Text.propTypes = {
  imageUrl: propTypes.string,
  text: propTypes.object.isRequired
} 

export default withStyles(styles)(Text);