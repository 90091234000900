import React, { Component } from 'react';
import {Grid, Card} from '@material-ui/core';
import {withStyles, withTheme} from '@material-ui/styles';
import {Toolbar, Table, Alerts, LoadingSpinner, Typography, SimpleDialog} from '../../../../../../components';
import {getSurveySchemas, deleteSurveySchema, createSurveySchema, editSurveySchemas} from '../../../../../../indexBackend';
import {LocalConvenienceStoreOutlined, MoreVert} from '@material-ui/icons';
import moment from 'moment';
import {NewSurveyDialog, EditSurveyDialog} from './components';
import defaults from './defaults.json';

const styles = theme => ({
  card: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0
  }
})

class Surveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: null,
      surveys: null,
      selected: null,
      selectedSurveys: [],
      dialogs: {
        newSurveyDialog: defaults.newSurveyDialog,
        editSurveyDialog: defaults.editSurveyDialog,
        deleteConfirmationDialog: defaults.deleteConfirmationDialog
      },
      filters: {
        search: "",
        status: defaults.filters.status
      }
    }
  }

  componentDidMount() {
    this.getSurveys();
  }

  getSurveys = async () => {
    const surveys = await getSurveySchemas();
    const id = window.location.pathname.split('/')[2]

    const filteredSurveys = surveys.data.data.filter(x => x.customerId === id);

    this.setState(state => ({
      ...state,
      surveys: filteredSurveys
    }), () => {
      this.createTableData();
    })
  }

  setSelected = (id, openDialog) => {
    const selected = [...this.state.surveys].find(x => x._id === id);

    if (selected) {
      this.setState(state => ({
        ...state,
        selected
      }))
    }
  }

  newLicenceFieldHandler = (event) => {
    this.setState(state => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        newSurveyDialog: {
          ...state.dialogs.newSurveyDialog,
          fields: {
            ...state.dialogs.newSurveyDialog.fields,
            [event.target.name]: event.target.value
          }
        }
      }
    }))
  }

  dialogStateHandler = (dialog, reset) => {
    this.setState(state => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        [dialog]: {
          ...state.dialogs[dialog],
          open: !state.dialogs[dialog].open
        }
      }
    }))
  }

  clearSelected = () => {
    this.setState(state => ({
      ...state,
      selected: null
    }))
  }

  selectionHandler = (customer) => {
    let selectedSurveys = [...this.state.selectedSurveys];
    if (customer) {
      const customerIndex = selectedSurveys.indexOf(customer);
      customerIndex > -1
        ? selectedSurveys.splice(customerIndex, 1)
        : selectedSurveys.push(customer);
    } else {
      selectedSurveys = selectedSurveys.length !== this.state.tableData.rows.length
        ? this.state.tableData.rows.map(x => x.id)
        : [];
    }

    this.setState(state => ({
      ...state,
      selectedSurveys
    }), () => {
      this.createTableData();
    });
  }

  newSurveyReset = () => {
    this.setState(state => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        newSurveyDialog: {
          ...state.dialogs.newSurveyDialog,
          fields: defaults.newSurveyDialog.fields
        }
      }
    }))
  }

  createTableData = () => {
    let surveys = [...this.state.surveys];
    const { search, ...rest } = this.state.filters;

    if(search || Object.values(rest.status).includes(true)) {
      surveys = surveys.filter(x => {
        return (
          (
            x.name?.toLowerCase().match(search.toLowerCase()) ||
            x.description?.toLowerCase().match(search.toLowerCase()) 
          ) &&
          (
            Object.values(rest.status).includes(true) ?
            (
              (rest.status.enabled && x.status === "Enabled") ||
              (rest.status.disabled && x.status === "Disabled")
            ) : true
          )
        )
      })
    }

    const labels = [
      {
        label: "Survey",
        sortable: true
      },
      {
        label: "Type",
        sortable: true
      },
      {
        label: "Status",
        sortable: true
      },
      {
        label: "Usage",
        sortable: true
      }
    ]

    const rows = surveys.map(x => {
      const status = {
        color: 'success',
        text: 'Enabled'
      }

      if(x.status === 'Disabled') {
        status.color = 'warning';
        status.text = 'Disabled';
      }

      const actions = [
        {"display": "Edit Survey", action: () => this.dialogStateHandler('editSurveyDialog')},
        {"display": x.status === "Enabled" ? "Disable" : "Enable", color: x.status === "Enabled" ? "warning" : "success", action: this.toggleSurveyStatus},
        {"display": "Delete", color: 'error', action: () => this.dialogStateHandler('deleteConfirmationDialog')},
      ]

      return {
        id: x._id,
        onClick: () => {
          this.setSelected(x._id);
          this.dialogStateHandler('editSurveyDialog');
        },
        cells: [
          {
            sortBy: x.name || x.description,
            type: 'text',
            options: {
              text: {
                primary: x.name,
                secondary: x?.description
              }
            }
          },
          "OneClick Survey",
          {
            sortBy: status.text,
            type: 'status',
            options: status
          },
          x.usage ? x.usage : 0,
          {
            type: 'actions',
            options: {
              button: <MoreVert size='small' />,
              click: () => this.setSelected(x._id),
              actions: actions,
              heading: "Survey Actions"
            }
          }
        ]
      }
    })

    this.setState(state => ({
      ...state,
      tableData: {
        labels,
        rows
      }
    }))
  }

  filterFieldHandler = (event) => {
    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [event.target.name]: event.target.value
      }
    }), () => {
      this.createTableData();
    })
  }

  toggleSurveyStatus = () => {
    const selected = {...this.state.selected}
    const pl = {
      _id: selected._id,
      status: selected.status === "Enabled" ? "Disabled" : "Enabled" 
    }

    editSurveySchemas(pl).then(data => {
      this.getSurveys();
      Alerts({type: 'success', text: 'Survey Successfully ' + data.data.data.status});
    })
    .catch(err => {
      Alerts({type: 'error', text: err.response.data.data || err.message})
    })
  }

  additionalFiltersHandler = (event) => {
    const selectors = event.target.name.split('-');

    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [selectors[0]]: {
          ...state.filters[selectors[0]],
          [selectors[1]]: event.target.checked
        }
      }
    }), () => {
      this.createTableData()
    })
  }


  newSurveyFieldHandler = (event) => {

    if (event.target.name.split('-').shift() === 'customfield') {
      const cf = [...this.state.dialogs.newSurveyDialog.fields.customFields];
      const index = event.target.name.split('-').pop()

      cf[index] = event.target.value;

      this.setState(state => ({
        ...state,
        dialogs: {
          ...state.dialogs,
          newSurveyDialog: {
            ...state.dialogs.newSurveyDialog,
            fields: {
              ...state.dialogs.newSurveyDialog.fields,
              customFields: cf
            }
          }
        }
      }))

      return
    }

    this.setState(state => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        newSurveyDialog: {
          ...state.dialogs.newSurveyDialog,
          fields: {
            ...state.dialogs.newSurveyDialog.fields,
            [event.target.name]: event.target.checked || event.target.value
          }
        }
      }
    }))
  }

  deleteSurveyHandler = async (id) => {
    const surveys = [...this.state.surveys];
    const index = surveys.findIndex(x => x._id === id);

    if (id) {
      deleteSurveySchema(id).then(data => {
        if (index !== -1) {
          const name = surveys[index].name
          surveys.splice(index, 1);

          Alerts({ text: `${name} successfully deleted`, type: 'success' });

          this.setState(state => ({
            ...state,
            surveys
          }), () => {
            this.createTableData();
            this.dialogStateHandler('deleteConfirmationDialog', true);
          })
        }
      })
    } else {
      const selectedSurveys = [...this.state.selectedSurveys];

      if (selectedSurveys.length === 0) {
        return
      }

      const results = await Promise.allSettled(selectedSurveys.map(async id => {
        await deleteSurveySchema(id);
        selectedSurveys.splice(selectedSurveys.indexOf(id), 1);
      }));

      const failedResults = results.filter(x => x.status === "rejected");
      const successfulResults = results.filter(x => x.status === "fulfilled");

      if (successfulResults.length > 0)
        if (failedResults.length > 0)
          Alerts({ text: `Successfully deleted ${successfulResults.length} survey${successfulResults.length > 1 ? "s" : ""}.<br/>Failed to delete ${failedResults.length} survey${failedResults.length > 1 ? "s" : ""}.`, type: "warning" });
        else
          Alerts({ text: `Successfully deleted ${successfulResults.length} survey${successfulResults.length > 1 ? "s" : ""}.`, type: "success" });
      else
        Alerts({ text: `Failed to delete ${failedResults.length} survey${failedResults.length > 1 ? "s" : ""}.`, type: "error" });

      this.setState(state => ({
        ...state,
        surveys,
        selectedSurveys: []
      }), () => {
        this.getSurveys();
        this.dialogStateHandler('deleteConfirmationDialog', true);
      })
    }
  }

  saveNewSurvey = () => {
    const id = window.location.pathname.split('/')[2]
    const {name, description, endpoint} = this.state.dialogs.newSurveyDialog.fields

    if(!name || !description) {
      return Alerts({type: 'warning', text: 'All fields must be populated'})
    }

    const payload = {
      name,
      description,
      customerId: id,
      errorResponse: "Error",
      successResponse: "Success",
      status: "Enabled",
      usage: 0,
      request: {
        endpoint: endpoint,
        method: "POST",
        body: [
          {
            internalName: "recordId",
            param: true
          },
          {
            internalName: "score",
            param: true
          },
          {
            internalName: "comment",
            param: true
          }
        ],
        header: [
          {
            internalName: "content-type",
            param: false,
            value: "application/json"
          }
        ],
        url: []
      }
    }

    this.state.dialogs.newSurveyDialog.fields.customFields.map(x => {
      if (x) {
        payload.request.body.push({ internalName: x, param: true })
      }
    })

    createSurveySchema(payload).then(data => {
      this.getSurveys();
      this.dialogStateHandler('newSurveyDialog')
      return Alerts({type: 'success', text: "Survey successfully created"})
    }).catch(err => {
      console.log(err)
      return Alerts({type: 'error', text: err.response.data.data || err.message})
    })
  }

  addCustomField = (event) => {
    this.setState(state => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        newSurveyDialog: {
          ...state.dialogs.newSurveyDialog,
          fields: {
            ...state.dialogs.newSurveyDialog.fields,
            customFields: [...state.dialogs.newSurveyDialog.fields.customFields, '']
          }
        }
      }
    }))
  }

  deleteCustomField = (event) => {
    const index = event.currentTarget.id.split('-').pop();
    const cf = [...this.state.dialogs.newSurveyDialog.fields.customFields];
    if (cf.length > 1) {
      cf.splice(index, 1);
    }

    this.setState(state => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        newSurveyDialog: {
          ...state.dialogs.newSurveyDialog,
          fields: {
            ...state.dialogs.newSurveyDialog.fields,
            customFields: cf
          }
        }
      }
    }))
  }

  editSurveyFieldHandler = (event) => {
    const field = event.target.name;

    switch(field) {
      case 'name':
      case 'description':
        this.setState(state => ({
          ...state,
          selected: {
            ...state.selected,
            [field]: event.target.value
          }
        }))
        break;
      case 'endpoint': 
        this.setState(state => ({
          ...state,
          selected: {
            ...state.selected,
            request: {
              ...state.selected.request,
              endpoint: event.target.value
            }
          }
        }))
        break;
      case "customField": 
        if(event.target.checked === false) {
          // Remove    
          const body = [...this.state.selected.request.body]
          
          body.splice(3, (body.length - 3));

          this.setState(state => ({
            ...state,
            selected: {
              ...state.selected,
              request: {
                ...state.selected.request,
                body
              }
            }
          }))
        } else {
          // Add
          this.addEditedCustomField();
        }
        break;
      default:
        break;
    }
  }

  saveEditedSurvey = () => {
    const selected = {...this.state.selected};

    selected.request.body = selected.request.body.filter(x => x.internalName !== "")

    editSurveySchemas(selected).then(data => {
      this.getSurveys();
      Alerts({type: 'success', text: 'Survey Successfully Updated'});
      this.dialogStateHandler('editSurveyDialog');
    })
    .catch(err => {
      Alerts({type: 'error', text: err.response.data.data || err.message})
    })
  }

  addEditedCustomField = (event) => {
    const body = [...this.state.selected.request.body]

    body.push({internalName: "", param: true})

    this.setState(state => ({
      ...state,
      selected: {
        ...state.selected,
        request: {
          ...state.selected.request,
          body
        }
      }
    }))
  }
  
  deleteEditedCustomField = (event) => {
    const index = event.currentTarget.id.split('-').pop();
    
    const body = [...this.state.selected.request.body]
    
    body.splice((parseInt(index) + 3), 1);

    this.setState(state => ({
      ...state,
      selected: {
        ...state.selected,
        request: {
          ...state.selected.request,
          body
        }
      }
    }))
  }

  cfEditFieldHandler = (event) => {
    const index = event.target.name.split('-').pop();
    
    const body = [...this.state.selected.request.body]

    body[parseInt(index) + 3].internalName = event.target.value

    this.setState(state => ({
      ...state,
      selected: {
        ...state.selected,
        request: {
          ...state.selected.request,
          body
        }
      }
    }))
  }
  
  render() {
    const { classes, theme } = this.props;

    if (!this.state.tableData) {
      return <LoadingSpinner />
    }

    return (
      <>
        <SimpleDialog
          open={this.state.dialogs.deleteConfirmationDialog.open}
          close={() => this.dialogStateHandler('deleteConfirmationDialog')}
          heading="Are you sure?"
          content={
            <>
              {
                !this.state.selected && this.state.selectedSurveys.length ?
                  <>
                    <Typography variant='body1'>
                      {`You're deleting ${this.state.selectedSurveys.length} records.`}
                    </Typography>
                    <br />
                  </>
                  : null
              }
              <Typography variant='body1'>
                Deleting a Survey can’t be reversed.
              </Typography>
            </>
          }
          submit={() => this.deleteSurveyHandler(this.state.selected?._id)}
          submitText="Delete"
        />
        <NewSurveyDialog
          open={this.state.dialogs.newSurveyDialog.open}
          close={() => this.dialogStateHandler('newSurveyDialog')}
          save={this.saveNewSurvey}
          fieldHandler={this.newSurveyFieldHandler}
          values={this.state.dialogs.newSurveyDialog.fields}
          exit={this.newSurveyReset}
          addCustomField={this.addCustomField}
          deleteCustomField={this.deleteCustomField}
          />
        <EditSurveyDialog
          open={this.state.dialogs.editSurveyDialog.open}
          close={() => this.dialogStateHandler('editSurveyDialog')}
          values={this.state.selected}
          fieldHandler={this.editSurveyFieldHandler}
          save={this.saveEditedSurvey}
          addCustomField={this.addEditedCustomField}
          deleteCustomField={this.deleteEditedCustomField}
          cfFieldHandler={this.cfEditFieldHandler}
        />
        <Grid container>
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Toolbar
                filterValue={this.state.filters.search}
                filterFieldHandler={this.filterFieldHandler}
                additionalFilters={this.state.filters}
                additionalFiltersHandler={this.additionalFiltersHandler}
                filterTranslation={{
                  "status": {
                    "category": "Status",
                    "enabled": "Enabled",
                    "disabled": "Disabled"
                  },
                }}
                recordNumber={this.state.surveys?.length || 0}
                selectedNumber={this.state.selectedSurveys?.length || 0}
                showingNumber={this.state.tableData?.rows?.length || 0}
                actions={[
                  {
                    onClick: () => this.dialogStateHandler('newSurveyDialog'),
                    color: theme.palette.info.main,
                    type: "add",
                    tooltip: "New Survey"
                  },
                  {
                    onClick: () => {this.clearSelected(); this.dialogStateHandler('deleteConfirmationDialog')},
                    color: theme.palette.error.main,
                    type: "delete",
                    disabled: !this.state.selectedSurveys.length,
                    tooltip: "Delete Survey"
                  }
                ]}
              />
              <Table
                dense
                tableData={this.state.tableData}
                selectionHandler={this.selectionHandler}
                selectedArr={this.state.selectedSurveys}
              />
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(withTheme(Surveys));