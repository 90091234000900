import React, { Component } from 'react';
import {Card} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import { Typography } from '../../../../components';
import PropTypes from 'prop-types';

const styles = theme => {
  const supportedColors = [
    'primary',
    'secondary',
    'info',
    'warning',
    'error'
  ]

  const style = {
    card: {
      backgroundColor: theme.palette.primary.main,
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2)
    },
    icon: {
      marginBottom: theme.spacing(2),
      fontSize: '40px',
      height: '40px',
      '& svg': {
        fontSize: 'inherit'
      }
    }
  }

  supportedColors.map(x => {
    style[x] = {
      backgroundColor: theme.palette[x].main,
      color: theme.palette[x].contrastText
    }
  })

  return style;
}

class Tile extends Component {
  render() {
    const {classes, color = 'primary', icon, primaryText, secondaryText} = this.props;
    return (
      <Card className={[classes.card, classes[color]].join(' ')} elevation={0}>
        <div className={classes.icon}>
          {icon}
        </div>
        <Typography variant='h5'>
          {primaryText}
        </Typography>
        <Typography variant='body1' bold>
          {secondaryText}
        </Typography>
      </Card>
    );
  }
}

Tile.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.element,
  primaryText: PropTypes.node,
  secondaryText: PropTypes.node
}

export default withStyles(styles)(Tile);