import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main } from './layouts';
import {AuthCheck} from './hoc'
import {
  Customers,
  Dashboard,
  CustomerInformation,
  Login,
  Results, 
  SurveyResponse
} from './views';
const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/surveyresponse"
      />
      <RouteWithLayout
        component={AuthCheck(Dashboard)}
        exact
        layout={Main}
        path='/dashboard'
      />
      <RouteWithLayout
        component={AuthCheck(Customers)}
        exact
        layout={Main}
        path='/customer-administration'
      />
      {/* <RouteWithLayout
        component={AuthCheck(Results)}
        exact
        layout={Main}
        path='/results'
      /> */}
      <Route
        component={SurveyResponse}
        exact
        path='/surveyresponse'
      />
      <RouteWithLayout
        component={AuthCheck(CustomerInformation)}
        exact
        layout={Main}
        path='/customer-administration/:id'
      />
      <Route
        component={Login}
        exact
        path='/login'
      />
    </Switch>
  )
}

export default Routes;